.card-review-img {
}
.card-review-img img {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  -o-object-fit: cover;
  object-fit: cover;

  padding: 0;
}

.card-review-img.col-sm-1 {
  padding: 0;
}
.card-review-description {
  color: #aaaaaa;
}
.user-review-card .card-header {
  border-bottom: 0;
}
.user-review-card {
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.card-review-user {
  color: #aaaaaa;
  font-size: 12px;
}
.user-review-card .dashboard-card-text {
  color: #585858;
  font-size: 16px;
}

.svg-inline--fa.fa-w-18.fa-star {
  margin-right: 2px;
}

.card-review-header.card-header {
  background-color: transparent;
}
