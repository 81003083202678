.settings-container {
  max-width: 1000px;
  margin: 80px auto;
}

.settings-container .title {
  text-align: left;
  margin-bottom: 20px;
  padding: 10px;
}

.settings-items {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: #FFCD46;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
}

.settings-items .settings-item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
}

#freq-select {
  border: transparent;
}

.about:hover,
.feedback:hover,
.contact:hover,
.tos:hover,
.pp:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}

.deleteaccount:hover {
  cursor: pointer;
}

.contact-text {
  color: #a8a8a8;
}

.chev-right-contact {
  margin-left: 20px;
  color: #FFCD46;
}

.upload-pdf {
  width: 100%;
}
.settings-item-row .edit-post-btn button {
  background-color: #FFCD46 !important;
  color: white !important;
  font-size: 16px !important;
  border-radius: 0 !important;
  border: 0 !important;
}
.settings-item-row .edit-post-btn button {
  align-self: center;
}
.settings-item-row .upload-file-label {
  cursor: pointer;
  background-color: #FFCD46;
  color: white;
  font-size: 16px;
  padding: 10px 25px;
}
.upload-pdf .edit-post-btn button {
  background-color: #FFCD46 !important;
  color: white !important;
  font-size: 16px !important;
  border-radius: 0 !important;
  border: 0 !important;
}
.upload-pdf .upload-button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
}

/* input[type="file"] {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
} */

.save-settings {
  background-color: #FFCD46 !important;
  border: none !important;
  padding: 5px 45px !important;
  border-radius: 0 !important;
  color: white;
}
.save-settings:active,
.save-settings:visited,
.save-settings:link,
.save-settings:focus,
.save-settings:hover,
.save-settings:focus-within {
  background-color: #FFCD46 !important;
}
.settings-item-row.pdf:hover{
  text-decoration: none;
  background-color: #F7F7F7;
}
.settings-item-row.pdf p{
  color:#FFCD46;
}
.settings-item-row.pdf path{
  fill:#FFCD46;
}
.settings-item-row.delete:hover{
  text-decoration: none;
  background-color: #F7EAEA;
}