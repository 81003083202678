.sc-chat-window {
  width: 370px;
  height: calc(50% - 60px);
  max-height: 590px;
  position: fixed;
  right: 25px;
  bottom: 0.5px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.2s ease-in-out;
  z-index: 10000;
  border: 1px solid #efefef;
}

.sc-chat-window.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 90px;
}

.sc-message-list {
  height: 80%;
  overflow-y: auto;
  background-color: white;
  background-size: 100%;
}

.sc-message--me {
  text-align: right;
}
.sc-message--them {
  text-align: left;
}

@media (max-width: 450px) {
  .sc-chat-window {
    width: 100%;
    height: 100%;
    max-height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
  .sc-chat-window {
    transition: 0.1s ease-linear;
  }
  .sc-chat-window.closed {
    bottom: 0px;
  }
}
