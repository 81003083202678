@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
html {
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
}

.green-txt {
  color: #FFCD46;
}

.green-bg {
  background-color: #FFCD46;
}
.App-link {
  color: #61dafb;
}

.btn,
.btn-secondary {
  border-color: transparent;
  border: 0;
}
.btn:hover,
.btn-secondary {
  border-color: transparent;
  border: 0;
}

.fa-star {
  height: 12px;
  margin: 10px 10px 10px 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
}

a {
  font-weight: 500;
}

a:focus {
  color: #1F2A37 !important;
  font-weight: 500;
  text-decoration: none;
}

.btn-white:focus {
  color: #1F2A37 !important;
  font-weight: 500;
  text-decoration: none;
}

 a:hover {
  color: #C69500 !important;
  font-weight: 500;
}

* {
  outline: none;
}

.btn:focus {
  outline: none;
}

.show {
  opacity: 1;
}

body,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rubik', sans-serif !important;
}

.btn-primary:focus,
.btn-primary:active,
.btn:focus,
.btn,
.btn:focus-within,
.button:focus,
.button:active {
  box-shadow: none !important;
  outline: 0px !important;
  background-color: transparent;
}

.btn-secondary:focus,
.btn-secondary:active {
  background-color: transparent !important;
}

button {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #ECECEC;
}

.profile-img .img-circle {
  max-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0 !important;
}

.dashboard-card {
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  border: 0 !important;
  /* -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09); */
  /* box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09); */
}

.dashboard-card:hover {
  /* -webkit-transform: scale(1.07); */

  /* -ms-transform: scale(1.05); */
  /* transform: scale(1.05); */
  /* -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.21),
    0 5px 15px 0 rgba(0, 0, 0, 0.18); */
  /* box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.21), */
    /* 0 5px 15px 0 rgba(0, 0, 0, 0.18); */
  cursor: pointer;
}

.lowerNav.d-flex.flex-row.justify-content-between.align-items-center {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}
.card {
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  /* border: solid 2px transparent; */
}

/* .card:hover {
  -webkit-transform: scale(1.03);
  z-index: 100;
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09);
} */
.card-img {
  /* border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0 !important; */
  border-radius: 30px !important;
}

.page-content.col-sm-12 {
  min-height: 100vh;
}
.Checkout.is-desktop .Modal-animationWrapper .Button {
  position: relative;
  border-radius: 4px;
  background-color: green !important;
  background-image: green !important;
  box-shadow: 0 1px 0 0 rgba(46, 86, 153, 0.15),
    inset 0 1px 0 0 rgba(46, 86, 153, 0.1),
    inset 0 -1px 0 0 rgba(46, 86, 153, 0.4);
  font-size: 17px;
  line-height: 21px;
  height: 37px;
  font-weight: 700;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.page-content {
  /* margin-top: 12.6rem; */
}

.edit-own-post-menu {
  margin-top: -60px;
  margin-bottom: 10px;
}

.buttonrowtwo .btn-secondary,
.buttonrowtwo .btn-secondary:hover,
.buttonrowtwo .btn-secondary:active,
.buttonrowtwo .btn-secondary:visited {
  background-color: #FFCD46 !important;
  background: #FFCD46 !important;
}
.user-cards-ratingss {
  width: 100%;
}

.post-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.rdtTimeToggle {
  background-color: #FFCD46;
}

.loginbutton.btn.btn-secondary.btn-lg.btn-block {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.05) !important;
  background-color: #FFCD46 !important;
}
.loginbutton:active {
  background-color: #FFCD46 !important;
}

.rdtTimeToggle {
  background-color: #FFCD46;
}

.forgot-pass {
  padding: 80px;
  border: 1px solid #efefef;
}

.forgot-pass-input.input-group label {
  display: block;
}

.forgot-pass-input label {
  font-size: 1rem !important;
  margin-bottom: 0 !important;
  padding-top: 10px;
}

.forgot-pass-input .form-control {
  width: 300px;
}

.forgot-pass-submit {
  margin-top: 50px;
}

.forgot-pass-submit button {
  padding: 10px 30px;
}
.login-with-google.btn-outline-primary {
  color: #ADB5BD;
  border-radius: 0;
  border: 1px solid #ADB5BD;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
  border: 1px solid #ADB5BD;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}
::-webkit-scrollbar {
  height: 10px;
  border: 1px solid #FFCD46 !important;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #FFCD46 !important;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:vertical {
  background: #FFCD46 !important;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.history-item-container .post-title {
  font-size: 1.2rem !important;
}
.history-item-container .rightside-info .info2 .info-prof-pic .left-side-pf {
  width: 30px !important;
  height: 30px !important;
}
.card-header {
  background-color: #fff !important;
  border-bottom: 0 !important;
}