* {
  margin: 0;
  padding: 0;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}

.video-main {
  width: 100%;
  height: 100vh;
  background-image: url("./field-bale.jpg");  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.4);  
}
.video-title {
  color:#FFCD46;
  font-weight: 500;
  font-size: 70px;
  text-transform: capitalize;
}
.video-subtitle {
  color:#FFFFFF;
  font-weight: 300;
  font-size: 42px;
  text-transform: uppercase;
}

#hide {
  position: fixed;
  top: 0vh;
  left: 0vh;
}

/* #height {
  position: fixed;
  top: 5vh;
  left: 5vh;
} */

#video-container {
  min-height: 200vh;
}

#hide {
  position: fixed;
  top:0 ;
  left:0;
  /* background-color: #eeeeee; */
  /* padding:20px; */
  height: 100vh;
  z-index: 1000;
  width: 100%;
}
#test {    
  position: fixed;
  top:0 ;
  left:0;
  height: 100vh;
  width: 100%
}