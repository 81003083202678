.sc-header {
  background: #FFCD46;
  max-height: 30px;
  padding: 5px;
  color: white;
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.sc-header--img {
  border-radius: 50%;
  align-self: center;
  padding: 10px;
  max-height: 40px;
  max-width: 40px;
}

.sc-header--team-name {
  align-self: center;
  padding: 10px;
  flex: 1;
  user-select: none;
  border-radius: 5px;
}

.sc-header--close-button {
  width: 18px;
  align-self: center;
  height: 18px;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 5px;
}

.sc-delete-icon {
  width: 18px;
  align-self: center;
  height: 20px;
  margin-right: 10px;
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
}

.sc-header--close-button img {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0px;
  }
}
