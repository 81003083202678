/* .dashboard-card {
  width: 100%;
  min-height: 320px;
  margin-bottom: 40px;
  background-color: white;
  box-shadow: 0px 8px 26px #00000055;
} */
.dashboard-card-body {
  padding: 10px;
  line-height: 1.4;
}
.renting-card-title {
  color: black;
  font-size: 18px;
  font-weight: bold;
}
.dashboard-card-text {
  font-size: 16px;
  color: #374151;
}
.card-img {
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-img img {
  width: 100%;
  vertical-align: middle;
}
.card-img-header {
  padding: 0;
}

.rentingout-pf-section-container {
  display: flex;
  padding-bottom: 10px;
}

.rentingout-pf-section-container .first {
  width: 10%;
}

.rentingout-pf-section-container .first img {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  padding: 0;
}

.rentingout-pf-section-container .second {
  width: 90%;
  display: flex;
  align-items: center;
}

.info-in-renting {
  font-size: 1.25em;
}

.info-in-renting p {
  width: 500px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title-rentingout {
  display: inline-block;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.info-in-renting span {
  padding-left: 10px;
}

.renting-status {
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 10px;
}
@media only screen and (max-width: 1500px) {
  .rentingout-pf-section-container .first{
  width:15%;}
  .rentingout-pf-section-container .second{
  width:75%;}
  }