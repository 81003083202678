.categories-in-dash {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.main-searchbutton {
  /* display: none; */
  background-color: #ffcd46 !important;
  padding: 0 60px;
  border: none;
  box-shadow: none;
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
}
/* .mainsearchinput:not(:placeholder-shown) + .main-searchbutton {
    display:block;
} */

.banner .mainsearchinput::placeholder {
  padding-left: 100px;
  padding-top: 25px;
}

.form-control::placeholder {
  /* font-size: 1.25rem;
    color: #b9bbb7!important;
    letter-spacing: 1px;
     */
  position: absolute;
  left: 0;
  white-space: nowrap;
  color: #b9bbb7 !important;
  word-break: break-all;
}

.no-results-div {
  min-height: 500px;
  width: 100%;
  padding: 20px;
  font-size: 1.4rem;
}

.no-results-span {
  text-align: center;
}

.clickrefresh {
  cursor: pointer;
  font-weight: 700;
}

.paginate {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.paginate a {
  padding: 1px 10px;
  border-radius: 2px;
  font-size: 25px;
}
.paginate a:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
.disabled-button {
  cursor: default !important;
}
.disabled-button:hover {
  background: rgba(0, 0, 0, 0) !important;
}

@media only screen and (max-width: 1400px) {
  .dashboard-card .card-img {
    height: 220px;
  }
  .page-content .banner {
    height: 550px;
  }
}
.banner {
  position: relative;
}
.banner::after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* .gm-style-iw.gm-style-iw-c{
  padding-right:10px!important;
  padding-top:10px!important;
} */

.gm-ui-hover-effect {
  right: -3px !important;
}

.dash-map-view-list-view span:hover {
  color: #ffcd46;
}

.map-marker-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-bottom: 0;
}

.map-hover-popup:hover {
  cursor: pointer;
}

/* .map-hover-popup:hover p {
  color: #ffcd46;
} */

.map-hover-popup img {
  max-height: 200px;
  border-radius: 8px;
}
.gm-style-iw-tc {
  display: none;
}

.gm-style .gm-style-iw-c {
  top: 0;
  left: -7px;
}

.mapcluster-container {
  /* height: 82vh; */
  height: 500px;
  min-height: 82vh
}

.gm-style-iw-d {
  overflow: hidden !important; 
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0;
  border-radius: 0.25rem;
}

.posts-in-dash {
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
}
.btn-dark {
  background-color: #1f2a37;
  color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
}
.btn-white {
  background-color: #FFFFFF;
  color: #1F2A37;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 8px;
  font-weight: 500;
  font-size: 18px;
  border: 1px solid #ECECEC;
  text-shadow: 0 !important;
}
button {
  text-shadow: none !important;
}
.modal-dialog {
  max-width: 900px !important;
  margin: 7vh auto !important;
}

.filter-toggle {
  width: 100%;
  cursor: pointer;
  padding: 1.5rem;
  margin-bottom: 0 !important;
  display: flex;
}
.filter-type {
  border: 1px solid #dee2e6;
  border-radius: 16px;
  margin-bottom: 1rem;
}
.filter-category,
.modal-title {
  font-weight: 700;
  font-size: 24px;
}

.filter-card {
  padding: 1.5rem;
}
.filter-card .collapse {
  padding: 1.5rem;
  border-top: 1px solid #ececec;
}

.toggle-btn {
  border-radius: 8px;
  border: 1px solid #dee2e6;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: #212529;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.5rem !important;
  outline: 0;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem 2rem !important;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.filter-modal .btn-primary:not(:disabled):not(.disabled).active,
.filter-modal .btn-primary:not(:disabled):not(.disabled):active,
.filter-modal .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ffcd46 !important;
  border: 1px solid #ffcd46;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.filter-modal .btn-primary {
  color: #e4e4e5 !important;
  background-color: #e4e4e5;
  border: 1px solid #e4e4e5;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-modal .btn-primary:hover {
  color: black !important;
  background-color: black !important;
  border: 2px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.filter-modal .btn-primary:not(:disabled):not(.disabled).active {
  color: #fff !important;
  background-color: black !important;
  border: 2px solid black !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/* svg {
  fill: rgb(242, 242, 242);
  height: 3.6rem;
  width: 3.6rem;
  margin: 0.2rem;
}

.rating-form-2 svg {
  height: 3rem;
  width: 3rem;
  margin: 0.5rem;
}

#radios label {
  position: relative;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}

input[type="radio"]+svg {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

input+svg {
  cursor: pointer;
}

input[class="super-happy"]:hover+svg,
input[class="super-happy"]:checked+svg,
input[class="super-happy"]:focus+svg {
  fill: rgb(0, 109, 217);
} */

/* The container */
.filter-radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.filter-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.filter-radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.filter-radio-container input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "✓";
  /* content: "✅"; */
  position: absolute;
  display: none;
  color: #fff;
}

/* Show the indicator (dot/circle) when checked */
.filter-radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.filter-radio-container .checkmark:after {
  top: -1px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  /* background: black; */
}
.option-label {
  font-size: 18px;
  font-weight: 400;
  padding: 0 15px;
}

.option-row {
  border-top: 1px solid #ececec !important;
  padding: 35px 20px;
}

.input-style {  
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid #ececec;
  width: 96px;
  /* height: 40px; */
  width: 100%;
}
.btn-hay-yellow {
  color: #fff;
  background-color: #ffcd46;
  padding: 10px 30px;
  border-radius: 8px;
  outline: 0;
  border: #ffcd46;
  font-size: 18px;
  font-weight: 500;
}
@media only screen and (max-width: 1400px) {
  .btn-hay-yellow {      
    padding: 10px 10px;   
    font-size: 16px;
  }
}
.btn-hay-yellow:focus, .btn-hay-yellow:active {
  background-color: #C69500; 
}

/* .absolute-Center {
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  
} */
.input-style-post {    
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid #ececec;
 
  width: 100%;
  font-size: 16px;
  color: black;
}

.input-style-post:disabled, .input-style:disabled {    
  /* flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px; */
  border: 2px solid #ececec;
 
  background-color: #f1f1f1ce;
  /* width: 100%;
  font-size: 16px; */
  color: black;
}

.input-style-post:focus, .input-style-post:active, .input-style:active, .input-style:focus {    
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  border: 2px solid #ffcd46;
  background-color: #fdfdfdce;
  width: 100%;
  font-size: 16px;
  color: black;
}
.input-style-post option {
  padding: 20px !important;
}



.container-custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-custom-radio:hover input~.custom-checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-custom-radio input:checked~.custom-checkmark {
  background-color: #454642;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-custom-radio input:checked~.custom-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-custom-radio .custom-checkmark:after {
  top: 9px;
  left: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}
.active-sort-option {
  filter: opacity(100%);  
}

.filter-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 18px ;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 100px;
  background-color: #ECECEC;
  color: #1D1F2A;
}

nav li {
  align-items: center;
}