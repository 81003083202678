.sc-message {
  max-width: 300px;
  max-height: fit-content;
  align-self: center;
  margin: auto;
  display: flex;
}

.sc-message--content {
  width: 100%;
}

.sc-message--content.sent {
  justify-content: flex-end;
  text-align: right;
}

.sc-message--text {
  padding: 10px 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  display: inline-block;
  max-width: 81%;
  position: relative;
  margin-left: 15%;
}

.sentMessage {
  width: calc(100% - 90px);
  word-wrap: break-word;
}

.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #FFCD46;
}

.sc-message--content.received .sc-message--text {
  position: relative;
    clear: both;
    display: inline-block;
    padding: 10px;
    margin: 0 0 0 0;
    background-color: #E0E0E0;
    word-wrap: break-word;
    max-width: 81%;
}

.sc-message--content.sent .timeStamp {
  font-size: 10px;
  color: grey;
  margin-bottom: 15px;
}

.sc-message--content.received .timeStamp {
  font-size: 10px;
  color: grey;
  margin-bottom: 15px;
}


@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}