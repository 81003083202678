@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#paddingPlaceholder::-webkit-input-placeholder {
  padding-left: 11px;
}

#paddingPlaceholder::-moz-placeholder {
  padding-left: 11px;
}

#paddingPlaceholder:-ms-input-placeholder {
  padding-left: 11px;
}

#paddingPlaceholder::-ms-input-placeholder {
  padding-left: 11px;
}

#paddingPlaceholder::placeholder {
  padding-left: 11px;
}

.dashboard-card  {
    width: 100%;
    min-height: 320px;
    /* margin-bottom: 40px; */
    background-color: white;
    /* box-shadow: 0px 8px 26px #00000055; */
}
.dashboard-card:hover  {
    text-decoration: none;
}

.dashboard-card-body {
    padding: 10px;
    line-height: 1.4;
}
.dashboard-card-title {
    color: black;
    font-size: 18px;
    /* letter-spacing: 1px; */
    font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dashboard-card-text {
    font-size: 18px;
    color: #374151;
    font-weight: 400;
}

.dash-username{
    font-size: 18px;
    color:#E7B10A;
    margin-bottom: 10px;
    display: flex;
flex-direction: row;
/* justify-content: center; */
align-items: center;
padding: 8px 12px;
/* gap: 8px; */

/* width: 90px;
height: 36px; */

background: rgba(255, 204, 29, 0.1);
border-radius: 40px;
}

.card-img {
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-img img {
    width: 100%;
    vertical-align: middle;
}
.card-img-header {
   padding: 0;
}

.dashboard-card .card-img {
    height: 300px;
}

.test {
    width: 20%;
    
}

a:hover {
    text-decoration: none !important;
}
.category-card {
    width: 100%;
    margin-bottom: 40px;
    /* margin-right: 10px; */
    background-color: white;
    box-shadow: 0px 3px 12px #00000055;
    padding: 0;
}

.categories-in-dash {
    width: 100%;
    /* padding: 0 15px 0 0; */
    /* margin: 0 30px; */
}
.categories-in-dash.row {
    margin-right: 15px;
    margin-left: 15px;
}

.category-card-title {
    color: black;
    font-size: 27px;
    font-weight: 500;
    padding: 10px 10px;
}
.card-category-img {
    height: 250px;
    overflow: hidden;
}
.card-category-img img {
    vertical-align: middle;
    width: auto;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    min-height: 100%;
}
.categories-in-dash.row {
    flex-wrap: nowrap;
}
.categories-in-dash.py-3.row{
    padding-left:20px;}
    .card-category-img{
    height:150px;
}
.category-card-title{
    font-size:18px;
}
@media only screen and (max-width: 1500px) {
    .card-category-img{
        height:100px;
    }
}
.categories-in-dash {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.main-searchbutton {
  /* display: none; */
  background-color: #ffcd46 !important;
  padding: 0 60px;
  border: none;
  box-shadow: none;
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
}
/* .mainsearchinput:not(:placeholder-shown) + .main-searchbutton {
    display:block;
} */

.banner .mainsearchinput::-webkit-input-placeholder {
  padding-left: 100px;
  padding-top: 25px;
}

.banner .mainsearchinput::-moz-placeholder {
  padding-left: 100px;
  padding-top: 25px;
}

.banner .mainsearchinput:-ms-input-placeholder {
  padding-left: 100px;
  padding-top: 25px;
}

.banner .mainsearchinput::-ms-input-placeholder {
  padding-left: 100px;
  padding-top: 25px;
}

.banner .mainsearchinput::placeholder {
  padding-left: 100px;
  padding-top: 25px;
}

.form-control::-webkit-input-placeholder {
  /* font-size: 1.25rem;
    color: #b9bbb7!important;
    letter-spacing: 1px;
     */
  position: absolute;
  left: 0;
  white-space: nowrap;
  color: #b9bbb7 !important;
  word-break: break-all;
}

.form-control::-moz-placeholder {
  /* font-size: 1.25rem;
    color: #b9bbb7!important;
    letter-spacing: 1px;
     */
  position: absolute;
  left: 0;
  white-space: nowrap;
  color: #b9bbb7 !important;
  word-break: break-all;
}

.form-control:-ms-input-placeholder {
  /* font-size: 1.25rem;
    color: #b9bbb7!important;
    letter-spacing: 1px;
     */
  position: absolute;
  left: 0;
  white-space: nowrap;
  color: #b9bbb7 !important;
  word-break: break-all;
}

.form-control::-ms-input-placeholder {
  /* font-size: 1.25rem;
    color: #b9bbb7!important;
    letter-spacing: 1px;
     */
  position: absolute;
  left: 0;
  white-space: nowrap;
  color: #b9bbb7 !important;
  word-break: break-all;
}

.form-control::placeholder {
  /* font-size: 1.25rem;
    color: #b9bbb7!important;
    letter-spacing: 1px;
     */
  position: absolute;
  left: 0;
  white-space: nowrap;
  color: #b9bbb7 !important;
  word-break: break-all;
}

.no-results-div {
  min-height: 500px;
  width: 100%;
  padding: 20px;
  font-size: 1.4rem;
}

.no-results-span {
  text-align: center;
}

.clickrefresh {
  cursor: pointer;
  font-weight: 700;
}

.paginate {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.paginate a {
  padding: 1px 10px;
  border-radius: 2px;
  font-size: 25px;
}
.paginate a:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
.disabled-button {
  cursor: default !important;
}
.disabled-button:hover {
  background: rgba(0, 0, 0, 0) !important;
}

@media only screen and (max-width: 1400px) {
  .dashboard-card .card-img {
    height: 220px;
  }
  .page-content .banner {
    height: 550px;
  }
}
.banner {
  position: relative;
}
.banner::after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* .gm-style-iw.gm-style-iw-c{
  padding-right:10px!important;
  padding-top:10px!important;
} */

.gm-ui-hover-effect {
  right: -3px !important;
}

.dash-map-view-list-view span:hover {
  color: #ffcd46;
}

.map-marker-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-bottom: 0;
}

.map-hover-popup:hover {
  cursor: pointer;
}

/* .map-hover-popup:hover p {
  color: #ffcd46;
} */

.map-hover-popup img {
  max-height: 200px;
  border-radius: 8px;
}
.gm-style-iw-tc {
  display: none;
}

.gm-style .gm-style-iw-c {
  top: 0;
  left: -7px;
}

.mapcluster-container {
  /* height: 82vh; */
  height: 500px;
  min-height: 82vh
}

.gm-style-iw-d {
  overflow: hidden !important; 
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0;
  border-radius: 0.25rem;
}

.posts-in-dash {
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
}
.btn-dark {
  background-color: #1f2a37;
  color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  grid-gap: 8px;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
}
.btn-white {
  background-color: #FFFFFF;
  color: #1F2A37;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  grid-gap: 8px;
  gap: 8px;
  font-weight: 500;
  font-size: 18px;
  border: 1px solid #ECECEC;
  text-shadow: 0 !important;
}
button {
  text-shadow: none !important;
}
.modal-dialog {
  max-width: 900px !important;
  margin: 7vh auto !important;
}

.filter-toggle {
  width: 100%;
  cursor: pointer;
  padding: 1.5rem;
  margin-bottom: 0 !important;
  display: flex;
}
.filter-type {
  border: 1px solid #dee2e6;
  border-radius: 16px;
  margin-bottom: 1rem;
}
.filter-category,
.modal-title {
  font-weight: 700;
  font-size: 24px;
}

.filter-card {
  padding: 1.5rem;
}
.filter-card .collapse {
  padding: 1.5rem;
  border-top: 1px solid #ececec;
}

.toggle-btn {
  border-radius: 8px;
  border: 1px solid #dee2e6;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: #212529;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.5rem !important;
  outline: 0;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem 2rem !important;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.filter-modal .btn-primary:not(:disabled):not(.disabled).active,
.filter-modal .btn-primary:not(:disabled):not(.disabled):active,
.filter-modal .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ffcd46 !important;
  border: 1px solid #ffcd46;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.filter-modal .btn-primary {
  color: #e4e4e5 !important;
  background-color: #e4e4e5;
  border: 1px solid #e4e4e5;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-modal .btn-primary:hover {
  color: black !important;
  background-color: black !important;
  border: 2px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.filter-modal .btn-primary:not(:disabled):not(.disabled).active {
  color: #fff !important;
  background-color: black !important;
  border: 2px solid black !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/* svg {
  fill: rgb(242, 242, 242);
  height: 3.6rem;
  width: 3.6rem;
  margin: 0.2rem;
}

.rating-form-2 svg {
  height: 3rem;
  width: 3rem;
  margin: 0.5rem;
}

#radios label {
  position: relative;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}

input[type="radio"]+svg {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

input+svg {
  cursor: pointer;
}

input[class="super-happy"]:hover+svg,
input[class="super-happy"]:checked+svg,
input[class="super-happy"]:focus+svg {
  fill: rgb(0, 109, 217);
} */

/* The container */
.filter-radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.filter-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.filter-radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.filter-radio-container input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "✓";
  /* content: "✅"; */
  position: absolute;
  display: none;
  color: #fff;
}

/* Show the indicator (dot/circle) when checked */
.filter-radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.filter-radio-container .checkmark:after {
  top: -1px;
  left: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  /* background: black; */
}
.option-label {
  font-size: 18px;
  font-weight: 400;
  padding: 0 15px;
}

.option-row {
  border-top: 1px solid #ececec !important;
  padding: 35px 20px;
}

.input-style {  
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid #ececec;
  width: 96px;
  /* height: 40px; */
  width: 100%;
}
.btn-hay-yellow {
  color: #fff;
  background-color: #ffcd46;
  padding: 10px 30px;
  border-radius: 8px;
  outline: 0;
  border: #ffcd46;
  font-size: 18px;
  font-weight: 500;
}
@media only screen and (max-width: 1400px) {
  .btn-hay-yellow {      
    padding: 10px 10px;   
    font-size: 16px;
  }
}
.btn-hay-yellow:focus, .btn-hay-yellow:active {
  background-color: #C69500; 
}

/* .absolute-Center {
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  
} */
.input-style-post {    
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid #ececec;
 
  width: 100%;
  font-size: 16px;
  color: black;
}

.input-style-post:disabled, .input-style:disabled {    
  /* flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px; */
  border: 2px solid #ececec;
 
  background-color: #f1f1f1ce;
  /* width: 100%;
  font-size: 16px; */
  color: black;
}

.input-style-post:focus, .input-style-post:active, .input-style:active, .input-style:focus {    
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  border: 2px solid #ffcd46;
  background-color: #fdfdfdce;
  width: 100%;
  font-size: 16px;
  color: black;
}
.input-style-post option {
  padding: 20px !important;
}



.container-custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-custom-radio:hover input~.custom-checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-custom-radio input:checked~.custom-checkmark {
  background-color: #454642;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-custom-radio input:checked~.custom-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-custom-radio .custom-checkmark:after {
  top: 9px;
  left: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}
.active-sort-option {
  -webkit-filter: opacity(100%);
          filter: opacity(100%);  
}

.filter-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 18px ;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 100px;
  background-color: #ECECEC;
  color: #1D1F2A;
}

nav li {
  align-items: center;
}
.map-marker-label {
    margin-left: 40px;
}
* {
  margin: 0;
  padding: 0;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}

.video-main {
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/field-bale.4290255d.jpg);  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,.4);  
}
.video-title {
  color:#FFCD46;
  font-weight: 500;
  font-size: 70px;
  text-transform: capitalize;
}
.video-subtitle {
  color:#FFFFFF;
  font-weight: 300;
  font-size: 42px;
  text-transform: uppercase;
}

#hide {
  position: fixed;
  top: 0vh;
  left: 0vh;
}

/* #height {
  position: fixed;
  top: 5vh;
  left: 5vh;
} */

#video-container {
  min-height: 200vh;
}

#hide {
  position: fixed;
  top:0 ;
  left:0;
  /* background-color: #eeeeee; */
  /* padding:20px; */
  height: 100vh;
  z-index: 1000;
  width: 100%;
}
#test {    
  position: fixed;
  top:0 ;
  left:0;
  height: 100vh;
  width: 100%
}
nav {
    background-color: #FFFFFF;
    color: #55575F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 2.7rem;
    /* height: 7vh; */
}

ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important;
}

li {
    margin-right: 1rem;
}

a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

a:hover {
    color: #f1c40f;
}
.login-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.login-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.login-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

/* .signup-modal input::placeholder,
.login-modal input::placeholder,
.restpass-modal input::placeholder,
.phone-verification-modal input::placeholder {
  padding-left: 50px;
} */

.login-modal .modal-header button {
  width: 10%;
}
.login-modal .modal-header h5 {
  font-size: 18px;
}

.login-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.login-modal .loginbutton {
  background-color: #FFCD46;
  border: none;
  border-radius: 0;
  margin: 30px 0 20px 0;
  color: white;
}

.login-modal .loginbutton:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
}

.login-modal .modal-body {
  padding: 2rem 2rem 0 2rem;
}

.login-modal .modal-body .form-group {
  justify-content: center;
}

.login-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.login-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.login-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.login-modal .social-logins-login button {
  width: 45%;
}

.login-modal input[type="email"],
.login-modal input[type="password"],
.login-modal input[type="text"],
.login-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.login-modal input[type="email"]:focus,
.login-modal input[type="password"]:focus,
.login-modal input[type="name"]:focus,
.login-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}

.login-modal input[type="email"],
.login-modal input[type="password"],
.login-modal input[type="name"],
.login-modal select.form-control,
.login-modal .password-input {
  padding-left: 40px;
}

.login-modal .form-control::-webkit-input-placeholder {
  color: #898989;
}

.login-modal .form-control::-moz-placeholder {
  color: #898989;
}

.login-modal .form-control:-ms-input-placeholder {
  color: #898989;
}

.login-modal .form-control::-ms-input-placeholder {
  color: #898989;
}

.login-modal .form-control::placeholder {
  color: #898989;
}

body {
  padding-right: 0 !important;
}

.signup-modal .modal-header {
  color: #fff;
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
  border-bottom-left-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
}

.signup-modal .modal-content {
  border-top-left-radius: 2rem !important;
  border-top-right-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
  border: none;
}

.signup-modal .modal-header h5 {
  width: 100%;
  text-align: center;
}

/* .signup-modal .modal-header button {
  width: 10%;
} */
.signup-modal .modal-header h5 {
  font-size: 18px;
}

.signup-modal .modal-header .close {
  color: #fff;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.signup-modal .loginbutton {
  background-color: #FFCD46;
  border: none;
  border-radius: 0;
  margin: 30px 0 20px 0;
  box-shadow: none;
  color: white !important;
}

.signup-modal .loginbutton:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
}

.signup-modal .modal-body {
  padding: 0 2rem;
}

.signup-modal .modal-body .form-group {
  justify-content: center;
}

.signup-modal .forgot-pass-login {
  font-weight: 500;
  cursor: pointer;
}

.signup-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.signup-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.signup-modal .social-logins-login button {
  width: 45%;
}

/* .signup-modal input[type="email"],
.signup-modal input[type="password"],
.signup-modal input[type="text"],
.signup-modal input[type="select"],
.signup-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.signup-modal input[type="email"]:focus,
.signup-modal input[type="password"]:focus,
.signup-modal input[type="name"]:focus,
.signup-modal input[type="select"],
.signup-modal select.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
} */

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
  cursor: pointer;
}

/* .signup-modal input[type="email"],
.signup-modal input[type="password"],
.signup-modal input[type="select"],
.signup-modal input[type="text"],
.signup-modal select.form-control,
.signup-modal .password-input {
  padding-left: 40px;
} */

.signup-modal .form-control::-webkit-input-placeholder {
  color: #898989;
  letter-spacing: 0px;
  font-weight: 300;
}

.signup-modal .form-control::-moz-placeholder {
  color: #898989;
  letter-spacing: 0px;
  font-weight: 300;
}

.signup-modal .form-control:-ms-input-placeholder {
  color: #898989;
  letter-spacing: 0px;
  font-weight: 300;
}

.signup-modal .form-control::-ms-input-placeholder {
  color: #898989;
  letter-spacing: 0px;
  font-weight: 300;
}

.signup-modal .form-control::placeholder {
  color: #898989;
  letter-spacing: 0px;
  font-weight: 300;
}

.signup-modal .city-select {
  font-size: 1.25rem;
  color: #898989;
  margin: 0%;
}

body {
  padding-right: 0 !important;
}
.signup-modal {
  width: 600px !important;
}
/* .form-control-lg {
  padding: 2rem 1rem !important;
} */
.restpass-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.restpass-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.restpass-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.restpass-modal .modal-header button {
  width: 10%;
}
.restpass-modal .modal-header h5 {
  font-size: 18px;
}

.restpass-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.restpass-modal .loginbutton {
  background-color: #FFCD46;
  border: none;
  border-radius: 0;
  margin: 30px 0 20px 0;
  box-shadow: none;
  color: white !important;
}

.restpass-modal .loginbutton:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
}

.restpass-modal .modal-body {
  padding: 4rem 3rem 0 2rem;
}

.restpass-modal .modal-body .form-group {
  justify-content: center;
}

.restpass-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.restpass-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.restpass-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.restpass-modal .social-logins-login button {
  width: 45%;
}

.restpass-modal input[type="email"],
.restpass-modal input[type="password"],
.restpass-modal input[type="text"],
.restpass-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.restpass-modal input[type="email"]:focus,
.restpass-modal input[type="password"]:focus,
.restpass-modal input[type="name"]:focus,
.restpass-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}

.restpass-modal input[type="email"],
.restpass-modal input[type="password"],
.restpass-modal input[type="name"],
.restpass-modal select.form-control,
.restpass-modal .password-input {
  padding-left: 40px;
}

.restpass-modal .form-control::-webkit-input-placeholder {
  color: #898989;
}

.restpass-modal .form-control::-moz-placeholder {
  color: #898989;
}

.restpass-modal .form-control:-ms-input-placeholder {
  color: #898989;
}

.restpass-modal .form-control::-ms-input-placeholder {
  color: #898989;
}

.restpass-modal .form-control::placeholder {
  color: #898989;
}

body {
  padding-right: 0 !important;
}

.tos-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tos-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.tos-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.tos-modal .modal-header button {
  width: 10%;
}
.tos-modal .modal-header h5 {
  font-size: 18px;
}

.tos-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.tos-modal .loginbutton {
  background-color: #FFCD46;
  border: none;
  border-radius: 0;
  margin: 30px 0 20px 0;
  box-shadow: none;
  color: white;
}

.tos-modal .loginbutton:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
  color: white;
}

.tos-modal .modal-body {
  padding: 4rem 3rem 0 2rem;
}

.tos-modal .modal-body .form-group {
  justify-content: center;
}

.tos-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.tos-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.tos-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.tos-modal .social-logins-login button {
  width: 45%;
}

.tos-modal input[type="email"],
.tos-modal input[type="password"],
.tos-modal input[type="tel"],
.tos-modal input[type="text"],
.tos-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.tos-modal input[type="email"]:focus,
.tos-modal input[type="password"]:focus,
.tos-modal input[type="name"]:focus,
.tos-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}

.tos-modal input[type="email"],
.tos-modal input[type="password"],
.tos-modal input[type="tel"],
.tos-modal input[type="name"],
.tos-modal select.form-control,
.tos-modal .password-input {
  padding-left: 40px;
}

.tos-modal .form-control::-webkit-input-placeholder {
  color: #cecece;
}

.tos-modal .form-control::-moz-placeholder {
  color: #cecece;
}

.tos-modal .form-control:-ms-input-placeholder {
  color: #cecece;
}

.tos-modal .form-control::-ms-input-placeholder {
  color: #cecece;
}

.tos-modal .form-control::placeholder {
  color: #cecece;
}

body {
  padding-right: 0 !important;
}

.tos-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tos-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.tos-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.tos-modal .modal-header button {
  width: 10%;
}
.tos-modal .modal-header h5 {
  font-size: 18px;
}

.tos-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.tos-modal .loginbutton {
  background-color: #FFCD46;
  border: none;
  border-radius: 0;
  margin: 30px 0 20px 0;
  box-shadow: none;
  color: white;
}

.tos-modal .loginbutton:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
  color: white;
}

.tos-modal .modal-body {
  padding: 4rem 3rem 0 2rem;
}

.tos-modal .modal-body .form-group {
  justify-content: center;
}

.tos-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.tos-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.tos-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.tos-modal .social-logins-login button {
  width: 45%;
}

.tos-modal input[type="email"],
.tos-modal input[type="password"],
.tos-modal input[type="tel"],
.tos-modal input[type="text"],
.tos-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.tos-modal input[type="email"]:focus,
.tos-modal input[type="password"]:focus,
.tos-modal input[type="name"]:focus,
.tos-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}

.tos-modal input[type="email"],
.tos-modal input[type="password"],
.tos-modal input[type="tel"],
.tos-modal input[type="name"],
.tos-modal select.form-control,
.tos-modal .password-input {
  padding-left: 40px;
}

.tos-modal .form-control::-webkit-input-placeholder {
  color: #898989;
}

.tos-modal .form-control::-moz-placeholder {
  color: #898989;
}

.tos-modal .form-control:-ms-input-placeholder {
  color: #898989;
}

.tos-modal .form-control::-ms-input-placeholder {
  color: #898989;
}

.tos-modal .form-control::placeholder {
  color: #898989;
}

body {
  padding-right: 0 !important;
}

.tos-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.tos-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.tos-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.tos-modal .modal-header button {
  width: 10%;
}
.tos-modal .modal-header h5 {
  font-size: 18px;
}

.tos-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.tos-modal .loginbutton {
  background-color: #FFCD46;
  border: none;
  border-radius: 0;
  margin: 30px 0 20px 0;
  box-shadow: none;
  color: white;
}

.tos-modal .loginbutton:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
}

.tos-modal .modal-body {
  padding: 4rem 3rem 0 2rem;
}

.tos-modal .modal-body .form-group {
  justify-content: center;
}

.tos-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.tos-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.tos-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.tos-modal .social-logins-login button {
  width: 45%;
}

.tos-modal input[type="email"],
.tos-modal input[type="password"],
.tos-modal input[type="tel"],
.tos-modal input[type="text"],
.tos-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.tos-modal input[type="email"]:focus,
.tos-modal input[type="password"]:focus,
.tos-modal input[type="name"]:focus,
.tos-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}

.tos-modal input[type="email"],
.tos-modal input[type="password"],
.tos-modal input[type="tel"],
.tos-modal input[type="text"],
.tos-modal select.form-control,
.tos-modal .password-input {
  padding-left: 40px;
}

.tos-modal .form-control::-webkit-input-placeholder {
  color: #898989;
}

.tos-modal .form-control::-moz-placeholder {
  color: #898989;
}

.tos-modal .form-control:-ms-input-placeholder {
  color: #898989;
}

.tos-modal .form-control::-ms-input-placeholder {
  color: #898989;
}

.tos-modal .form-control::placeholder {
  color: #898989;
}

body {
  padding-right: 0 !important;
}





.newpost-modal .modal-header{
    color:#fff;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; 
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
    }
    

    .newpost-modal .modal-content{
        border-radius: 0!important;
        border:none;
    }

    .newpost-modal .modal-header h5 {
        width:90%;
        text-align: center;
    }
    
    .newpost-modal .modal-header button {
        width:10%;
    }
    .newpost-modal .modal-header h5{
        font-size: 18px;
        }
    
    .newpost-modal .modal-header .close{
        color:#FFCD46;
        opacity: 1;
        text-shadow: none;
        font-size: x-large;
    }
    
    .newpost-modal .loginbutton{
        background-color: #FFCD46;
        color:white;
        border:none;
        border-radius: 0;
        margin:10px 0 10px 0;
        box-shadow: none;
        text-shadow: 0 2px 4px rgba(0,0,0,0.10);
        
    }
    
    .newpost-modal .loginbutton:hover{
        background-color: rgb(100, 145, 43);
        border:none;
        border-radius: 0;
        border-color:rgb(100, 145, 43);
        box-shadow: none;
    }
    
    .newpost-modal .modal-body{
        padding:2rem 3rem 2rem 3rem;
    }
    
    .newpost-modal .modal-body .form-group{
        justify-content: center;
    }
    
    .newpost-modal .forgot-pass-login{
        color:#ADB5BD!important;
       cursor: pointer;
       font-weight: 500;
    }
    
    
    .newpost-modal .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0;
        border-top: none;
        border-bottom-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
    }
    .newpost-modal .social-logins-login{
        display:flex;
        justify-content: space-around;
        border-top:1px solid #efefef;
        border-bottom:1px solid #efefef;
        padding:20px 0;
    }
    
    .newpost-modal .social-logins-login button {
        width:45%;
    }
    
    .newpost-modal input[type="email"], .newpost-modal input[type="password"], .newpost-modal input[type="text"],
    .newpost-modal select.form-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #000000;
      box-shadow: none;
      border-radius: 0;
    }
    
    .newpost-modal input[type="email"]:focus, .newpost-modal input[type="password"]:focus, .newpost-modal input[type="name"]:focus,
    .newpost-modal select.form-control:focus {
      box-shadow: none;
    }

   


.newpost-modal input[type="email"], .newpost-modal input[type="password"], .newpost-modal input[type="name"], .newpost-modal select.form-control, .newpost-modal .password-input{
    padding-left:40px;
}

.newpost-modal .form-control::-webkit-input-placeholder {
    color:#898989;

}

.newpost-modal .form-control::-moz-placeholder {
    color:#898989;

}

.newpost-modal .form-control:-ms-input-placeholder {
    color:#898989;

}

.newpost-modal .form-control::-ms-input-placeholder {
    color:#898989;

}

.newpost-modal .form-control::placeholder {
    color:#898989;

}

.newpost-modal .form-group{
    margin-bottom: 0px;
}

body { padding-right: 0 !important }




.about-modal .modal-header{
    color:#fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
    }
    

    .about-modal .modal-content{
        border-radius: 0!important;
        border:none;
    }

    .about-modal .modal-header h5 {
        width:90%;
        text-align: center;
    }
    
    .about-modal .modal-header button {
        width:10%;
    }
    .about-modal .modal-header h5{
        font-size: 18px;
        }
    
    .about-modal .modal-header .close{
        color:#FFCD46;
        opacity: 1;
        text-shadow: none;
        font-size: x-large;
    }
    
    .about-modal .loginbutton{
        background-color: #FFCD46;
        border:none;
        border-radius: 0;
        margin:30px 0 20px 0;
        box-shadow: none;
    color:white;

    }
    
    .about-modal .loginbutton:hover{
        background-color: rgb(100, 145, 43);
        border:none;
        border-radius: 0;
        border-color:rgb(100, 145, 43);
        box-shadow: none;
    }
    
    .about-modal .modal-body{
        padding:3rem 3rem 0 2rem;
    }
    
    .about-modal .modal-body .form-group{
        justify-content: center;
    }
    
    .about-modal .forgot-pass-login{
        color:#ADB5BD!important;
       cursor: pointer;
       font-weight: 500;
    }
    
    
    .about-modal .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0;
        border-top: none;
        border-bottom-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
    }
    .about-modal .social-logins-login{
        display:flex;
        justify-content: space-around;
        border-top:1px solid #efefef;
        border-bottom:1px solid #efefef;
        padding:20px 0;
    }
    
    .about-modal .social-logins-login button {
        width:45%;
    }
    
    .about-modal input[type="email"], .about-modal input[type="password"], .about-modal input[type="text"],
    .about-modal select.form-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #000000;
      box-shadow: none;
      border-radius: 0;
    }
    
    .about-modal input[type="email"]:focus, .about-modal input[type="password"]:focus, .about-modal input[type="name"]:focus,
    .about-modal select.form-control:focus {
      box-shadow: none;
    }

    .login-with-google.btn-outline-primary{
        border-color:#ADB5BD;
        color:#ADB5BD;
        border-radius: 0;
    }

    .login-with-google.btn-outline-primary:hover{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
    }

    .login-with-fb.btn.btn-primary{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
        border-radius: 0;
    }

    .login-with-fb.btn.btn-primary:hover{
        border:1px solid #ADB5BD;
        background-color:#fff!important;
        color:#ADB5BD;
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
    }


    .email-icon-modal, .pass-icon-modal{
        position: absolute;
        top:12px;
        left:20px;
        color:#ADB5BD;
    }

    .eye-icon-modal{
        position: absolute;
        top:15px;
        right:25px;
        color:#FFCD46;
        
    }


.about-modal input[type="email"], .about-modal input[type="password"], .about-modal input[type="name"], .about-modal select.form-control, .about-modal .password-input{
    padding-left:40px;
}

.about-modal .form-control::-webkit-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::-moz-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control:-ms-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::-ms-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::placeholder {
    color:#CECECE
    ;

}

body { padding-right: 0 !important }
.navbar {
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0);
}

.displayContents {
  display: contents !important;
  margin: 0 10px;
  background-color: none !important;
}
.btn-no-bg.btn-secondary {
  background-color: none;
  outline: none;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: white;
  border-color: none;
  border: 0;
  outline: none;
}

.upper-nav {
  width: 100%;
}
.lowerNav {
  width: 100%;
}
.upper-nav .user-profile-dropdown,
.upper-nav .displayContents .btn {
  background: none;
  border: 0;
}
/* .upper-nav .btn, .upper-nav .btn-secondary  {
  background: none ;
} */
.navLink {
  margin: 0 10px;
}
/* .my-container {
  position: relative;
} */
.my-navbar-fixed {
  background: rgba(255, 255, 255, 0.95);
  /* position: fixed !important; */
  top: 0;
  width: 100%;
  box-shadow: 0px 1px 0px #ECECEC;
}

.upper-nav .mainsearchinput::-webkit-input-placeholder {
  padding-left: 45px;
  padding-top: 6px;
  font-size: 16px;
}

.upper-nav .mainsearchinput::-moz-placeholder {
  padding-left: 45px;
  padding-top: 6px;
  font-size: 16px;
}

.upper-nav .mainsearchinput:-ms-input-placeholder {
  padding-left: 45px;
  padding-top: 6px;
  font-size: 16px;
}

.upper-nav .mainsearchinput::-ms-input-placeholder {
  padding-left: 45px;
  padding-top: 6px;
  font-size: 16px;
}

.upper-nav .mainsearchinput::placeholder {
  padding-left: 45px;
  padding-top: 6px;
  font-size: 16px;
}

.notification-circle {
  background-color: #FFCD46;
  border-radius: 50%;
  border: 1px solid #ffffff;
  padding: 0px 6px;
  margin-left: -30px;
  text-align: center;
  color: white !important;
}
.user-profile-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile-img {
  width: 48px;
  height: 48px;
  
}
.user-profile-dropdown .green-txt {
  margin-left: 3px;
  text-transform: capitalize;
  box-shadow: none !important;
  text-shadow: none !important;
}

.user-profile-dropdown .green-txt span {
  min-width: 80px;
}

/* .profile-img img {
  width: 100%;
} */
.img-circle {
  margin-right: 10px;
  /* border-radius: 50%; */
}
.hr-nav {
  /* border-top: 2px solid #C9C9C9; */
  width: 100%;
  margin: 15px 0 10px;
}
.hr-dropdown {
  border-top: 1px solid #aaaaaa;
}
.lowerNav {
  /* margin-bottom: 10px; */
}
.lowerNav a {
  color: #FFCD46;
  cursor: pointer;
  font-weight: 500;
  margin-right: 20px;
}
.lowerNav a:hover {
  text-decoration: none;
}

.dropdown-container.dropdown-menu.show {
  position: absolute !important;
  top: 0 !important;
  left: 35px !important;
  -webkit-transform: none !important;
          transform: none !important;
  border: 0 !important;
}

.nav-dropdown.dropdown-menu.show {
  position: absolute !important;
  top: auto !important;
  left: auto !important;
  -webkit-transform: none !important;
          transform: none !important;
  width: 400px;
  max-height: 500px;
  overflow-y: scroll;
}

.dropdown-option {
  text-align: center;
  color: #FFCD46;
}
.dropdown-option:hover {
  text-decoration: none;
}
.dropdown-container {
  padding: 20px;
  max-height: 440px;
  overflow-y: auto;
}
.dropdown-container.dropdown-menu {
  text-align: center;
  padding: 20px;
}
.dropdown-menu.nav-dropdown {
  padding: 20px;
}
.dropdown-title {
  color: #FFCD46;
  font-weight: 500;
  text-align: right;
}
.nav-dropdown {
  padding: 20px;
}
.notification-row {
  /* margin-top: 15px;
  margin-bottom: 15px; */
  cursor: pointer;
}
.col-flex {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.row-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  background-image: url(/static/media/banner.a867bb0a.jpg);
  background-position: 50% 80%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 80px;
  /* margin-top: 80px; */
}
.upper-nav form {
  width: 600px;
}
.upper-nav input[type="text"] {
  /* height: 60px; */
  border-radius: 0;
  box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.25);
}
.upper-nav .form-search {
  display: flex;
  flex-direction: row;
}
.upper-nav .banner-search-bar {
  /* z-index: 550; */
}
.upper-nav .has-search .form-control {
  padding-left: 3rem;
}
.upper-nav .has-search .form-control-feedback {
  position: absolute;
    left: 3px;
    top: 9px;
    font-size: 5rem;
    /* font-weight: 100; */
    z-index: 600;
    display: block;
    /* width: 4rem; */
    /* height: 100%; */
    line-height: 1rem;
    text-align: center;
    pointer-events: none;
    color: red;
    padding: 0.3rem;
}
/* .upper-nav .form-control-feedback img {
  width: 2rem;
} */
.banner form {
  width: 50%;
}
.banner input[type="text"] {
  height: 80px;
  border-radius: 0;
}
.form-search {
  display: flex;
  flex-direction: row;
}
.banner-search-bar {
  /* z-index: 550; */
}
.banner .has-search .form-control {
  padding-left: 8rem;
}
.banner .has-search .form-control-feedback {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 5rem;
  font-weight: 100;
  z-index: 7;
  display: block;
  width: 7rem;
  height: 100%;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: red;
  padding: 0.8rem;
}
.banner .form-control-feedback img {
  width: 4rem;
}
.location {
  width: 400px;
  height: auto;
  padding: 0;
  padding-bottom: 10px;
  font-size: 14px;
}
.location .dropdown-option {
  margin-right: 0;
  text-align: left;
  color: #585858;
}
.location .dropdown-option a {
  text-align: left;
  font-size: 14px;
}
.location .dropdown-opt-inner:hover {
  background-color: #FFCD46;
  color: white;
}
.location .dropdown-header {
  width: 100%;
  margin-top: 0;
  height: 40px;
  background-color: #ADB5BD;
  color: white;
  font-size: 20px;
  font-weight: 400;
}
.location .form-search {
  padding: 10px 30px;
}
.dropdown-opt-inner {
  width: 100%;
  height: 50px;
  padding-left: 30px;
  display: flex;
  font-size: 14px;
  align-items: center;
}
.lowerNav .user-profile-dropdown {
  background: none;
}
.filter {
  width: 500px;
}
.filter-row {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px 0 0;
  align-items: center;
  margin: 15px 0;
}
.filter-row p {
  text-align: left;
}
.filter-checkbox-box:first-child {
  margin-left: 30px;
  /* margin-right: 15px; */
}
.filter-checkbox-box:last-child {
  margin-right: 30px;
  /* margin-left: 15px; */
}
.filter-col {
  padding: 0;
}
.filter-row.row::before,
.filter-row.row::after {
  display: none;
}
.filter-checkbox-box .btn-primary:not(:disabled):not(.disabled).active,
.filter-checkbox-box .btn-primary:not(:disabled):not(.disabled):active,
.filter-checkbox-box .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #FFCD46;
  border: 1px solid #FFCD46;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.filter-checkbox-box .btn-primary {
  color: #fff;
  background-color: white;
  border: 1px solid #FFCD46;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-checkbox-box .btn-primary:hover {
  color: #fff;
  background-color: white;
  border: 2px solid #FFCD46;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.btn-apply.btn.btn-secondary {
  background-color: #FFCD46;
  color: white;
  font-size: 14px;
  border-radius: 0;
  border: 0;
  padding: 5px 35px;
}
.btn-reset.btn.btn-secondary {
  background-color: rgba(139, 197, 63, 0);
  color: white;
  font-size: 20px;
  border-radius: 0;
  border: 0;
  padding: 0;
  padding-right: 15px;
  align-self: flex-end;
}
.btn-reset {
  align-self: flex-end;
  background-color: rgba(139, 197, 63, 0);
  color: white;
  font-size: 23px;
  border-radius: 0;
  border: 0;
  padding: 0;
  padding-right: 15px;
  align-self: flex-end;
  height: 100%;
}
.btn-reset-all {
  align-self: flex-end;
  background-color: #FFCD46;
  color: #fff;
  font-size: 18px;
  border-radius: 0;
  border: 0;
  padding: 0 15px;
  align-self: flex-end;
  height: 100%;
}
.button-nav button {
  color: #FFCD46;
  font-size: 15px;
  border: 0;
  background: none;
}
.date {
  width: 750px;
}
.DateRangePicker__CalendarSelection {
  background-color: #FFCD46;
  border: 1px solid rgb(125, 179, 56);
}

.DateRangePicker__WeekdayHeading abbr[title] {
  color: #585858;
}
.DateRangePicker__MonthHeader {
  color: #585858;
}
.DateRangePicker__CalendarSelection--single:hover {
  background-color: white;
  border: 1px solid rgb(125, 179, 56);
}
.DateRangePicker__PaginationArrowIcon--next {
  border-left: 8px solid #FFCD46;
}

.button-nav button:focus,
.button-nav button:active,
.button-nav button:hover,
.button-nav button:visited,
.button-nav button:focus-within {
  background-color: none;
  outline: none;
  border: none;
}

.user-profile-dropdown.btn.btn-secondary {
  color: #FFCD46;
  font-size: 15px;
  border: 0;
  background: none;
  background-color: none;
  outline: none;
  border: none;
}
.btn-success:active:focus,
.user-profile-dropdown.btn.btn-secondary:hover,
.user-profile-dropdown.btn.btn-secondary:active,
.user-profile-dropdown.btn.btn-secondary:visited,
.user-profile-dropdown.btn.btn-secondary:focus,
.user-profile-dropdown.btn.btn-secondary:focus-within {
  color: #FFCD46;
  font-size: 15px;
  border: 0;
  background: none;
  background-color: none;
  outline: none;
  border: none;
}

.dropdown button:focus {
  background: transparent;
}
.btn {
  color: #FFCD46;
  background-color: #712687;
  border-color: #9409b5;
  background: none;
  color: white;
  /* text-transform: uppercase; */
}

.btn.focus,
.btn:focus {
  color: #fff;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
  background: none;
}
.btn:focus-within {
  color: #fff;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
  background: none;
}

.btn:hover {
  color: #fff;
  -webkit-filter: brightness(85%);
          filter: brightness(85%);
  background: none;
}

.btn.active,
.btn:active,
.open > .dropdown-toggle.btn {
  color: #fff;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
  background: none;
}

.btn.active.focus,
.btn.active:focus,
.btn.active:hover,
.btn:active.focus,
.btn:active:focus,
.btn:active:hover,
.open > .dropdown-toggle.btn.focus,
.open > .dropdown-toggle.btn:focus,
.open > .dropdown-toggle.btn:hover {
  color: #fff;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
  background: none;
  outline-offset: none !important;
  box-shadow: none;
}

.btn.active,
.btn:active,
.open > .dropdown-toggle.btn {
  color: #fff;
  background-image: none;
  background: none;
}

.btn.disabled.focus,
.btn.disabled:focus,
.btn.disabled:hover,
.btn[disabled].focus,
.btn[disabled]:focus,
.btn[disabled]:hover,
fieldset[disabled] .btn.focus,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn:hover {
  color: #fff;
  background: none;
}
.btn .badge {
  color: #fff;
  /* background: none; */
}
.btn-secondary {
  /* color: #FFCD46; */
  /* background-color: #712687;
  border-color: #9409b5; */
  /* background: none; */
  /* text-transform: uppercase; */
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
  background: none;
}
.btn-secondary:focus-within {
  color: #fff;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
  background: none;
}

.btn-secondary:hover {
  color: #fff;
  -webkit-filter: brightness(85%);
          filter: brightness(85%);
  background: none;
}

.btn-secondary.active,
.btn-secondary:active,
.open > .dropdown-toggle.btn-secondary {
  color: #fff;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
  background: none;
}

.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary:active.focus,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open > .dropdown-toggle.btn-secondary.focus,
.open > .dropdown-toggle.btn-secondary:focus,
.open > .dropdown-toggle.btn-secondary:hover {
  color: #fff;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
  background: none;
}

.btn-secondary.active,
.btn-secondary:active,
.open > .dropdown-toggle.btn-secondary {
  color: #fff;
  background-image: none;
  background: none;
}

.btn-secondary.disabled.focus,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:hover,
.btn-secondary[disabled].focus,
.btn-secondary[disabled]:focus,
.btn-secondary[disabled]:hover,
fieldset[disabled] .btn-secondary.focus,
fieldset[disabled] .btn-secondary:focus,
fieldset[disabled] .btn-secondary:hover {
  color: #fff;
  background: none;
}
.btn-secondary .badge {
  color: #fff;
  /* background: none; */
}
.btn-secondary:hover {
  color: white;
  background-color: transparent;
  border-color: transparent;
}

.green-txt.btn-no-bg.login-button.btn.btn-secondary {
  border: none;
  outline: none;
  margin-right: 20px;
  color: #FFCD46;
  font-weight: 500;
}

.guest-buttons .login-button:hover,
.guest-buttons .login-button:focus,
.guest-buttons .login-button:active,
.guest-buttons .login-button:visited {
  /* box-shadow: none !important; */
  outline: 0px !important;
}

.guest-buttons .signup-button {
  font-weight: 500;
  background-color: #FFCD46;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 0;
}

.guest-buttons .signup-button:hover,
.guest-buttons .signup-button:focus,
.guest-buttons .signup-button:active,
.guest-buttons .signup-button:visited {
  font-weight: 500;
  background-color: #FFCD46;
  color: #fff;
  border: none;
  outline: none;
  /* box-shadow: none !important; */
  outline: 0px !important;
}

.ReactStickyHeader_root {
  width: 100%;
  position: relative;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.ReactStickyHeader_fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 60;
}

.ReactStickyHeader_background,
.ReactStickyHeader_foreground,
.ReactStickyHeader_midground,
.ReactStickyHeader_background-bg {
  background-position: center top;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 30;
}

.ReactStickyHeader_midground {
  position: fixed;
  z-index: 20;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.ReactStickyHeader_background-bg {
  z-index: 10;
}

.new-post-button {
  background-color: #FFCD46 !important;
  border: none !important;
  padding: 5px 45px !important;
  border-radius: 0 !important;
  color: white;
}

.new-post-button:active,
.new-post-button:visited,
.new-post-button:link,
.new-post-button:focus,
.new-post-button:focus-within {
  background-color: #FFCD46 !important;
  border: none !important;
  padding: 5px 45px !important;
  border-radius: 0 !important;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  color: white !important;
}

.green-bg.signup-button.btn:active,
.green-bg.signup-button.btnn:visited,
.green-bg.signup-button.btn:focus,
.green-bg.signup-button.btn:focus-within {
  background-color: #FFCD46 !important;
  border: none !important;
  border-radius: 0 !important;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  color: white;
}

.form-search.has-search.input-group {
  background-color: #fff;
  
}

.main-searchbutton2 {
  display: none;
  display: block;
  background-color: #FFCD46 !important;
  padding: 0 60px;
  border: none;
  box-shadow: none;
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
}

.main-searchbutton2:hover,
.search-link:hover {
  text-decoration: none;
}

.mainsearchinput:not(:placeholder-shown) + .main-searchbutton2 {
  display: block;
}

.nav-icons .a,
.nav-icons .b {
  fill: none;
}
.nav-icons .b {
  stroke: #585858;
  stroke-linejoin: round;
  stroke-width: 2px;
}
.navLink.active .nav-icons .b {
  stroke: #FFCD46;
}

.displayContents .user-profile-dropdown span.green-txt {
  min-width: 100px !important;
}

@media only screen and (max-width: 1500px) {
  .upper-nav form {
    width: 100%;
    max-width: 400px;
    /* width: 500px; */
  }
}
.notification-row.row {
  font-size: 12px;
}
.read-date {
  display: flex;
  align-items: center;
}
.notificationlink:hover {
  text-decoration: none;
}
.edit-profile-modal .editprofile-buttons .cancel-btn.cancel-editp {
  border: 1px solid #FFCD46 !important;
}

.sc-chat-window {
  width: 370px;
  height: calc(50% - 60px);
  max-height: 590px;
  position: fixed;
  right: 25px;
  bottom: 0.5px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  z-index: 10000;
  border: 1px solid #efefef;
}

.sc-chat-window.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 90px;
}

.sc-message-list {
  height: 80%;
  overflow-y: auto;
  background-color: white;
  background-size: 100%;
}

.sc-message--me {
  text-align: right;
}
.sc-message--them {
  text-align: left;
}

@media (max-width: 450px) {
  .sc-chat-window {
    width: 100%;
    height: 100%;
    max-height: 100%;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
  }
  .sc-chat-window {
    -webkit-transition: 0.1s ease-linear;
    transition: 0.1s ease-linear;
  }
  .sc-chat-window.closed {
    bottom: 0px;
  }
}

.sc-header {
  background: #FFCD46;
  max-height: 30px;
  padding: 5px;
  color: white;
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.sc-header--img {
  border-radius: 50%;
  align-self: center;
  padding: 10px;
  max-height: 40px;
  max-width: 40px;
}

.sc-header--team-name {
  align-self: center;
  padding: 10px;
  flex: 1 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 5px;
}

.sc-header--close-button {
  width: 18px;
  align-self: center;
  height: 18px;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 5px;
}

.sc-delete-icon {
  width: 18px;
  align-self: center;
  height: 20px;
  margin-right: 10px;
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
}

.sc-header--close-button img {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0px;
  }
}

.sc-message {
  max-width: 300px;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  align-self: center;
  margin: auto;
  display: flex;
}

.sc-message--content {
  width: 100%;
}

.sc-message--content.sent {
  justify-content: flex-end;
  text-align: right;
}

.sc-message--text {
  padding: 10px 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  display: inline-block;
  max-width: 81%;
  position: relative;
  margin-left: 15%;
}

.sentMessage {
  width: calc(100% - 90px);
  word-wrap: break-word;
}

.sc-message--content.sent .sc-message--text {
  color: white;
  background-color: #FFCD46;
}

.sc-message--content.received .sc-message--text {
  position: relative;
    clear: both;
    display: inline-block;
    padding: 10px;
    margin: 0 0 0 0;
    background-color: #E0E0E0;
    word-wrap: break-word;
    max-width: 81%;
}

.sc-message--content.sent .timeStamp {
  font-size: 10px;
  color: grey;
  margin-bottom: 15px;
}

.sc-message--content.received .timeStamp {
  font-size: 10px;
  color: grey;
  margin-bottom: 15px;
}


@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}
.sc-user-input {
  min-height: 40px;
  margin: 0px;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #f4f7f9;
  -webkit-transition: background-color .2s ease,box-shadow .2s ease;
  transition: background-color .2s ease,box-shadow .2s ease;
  border-top-style: solid;
  border-width: 1px;
}


.sc-user-input--text {
  width: 300px;
  resize: none;
  border: none;
  outline: none;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: black;
  -webkit-font-smoothing: antialiased;
  max-height: 40px;
  overflow: scroll;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  
}


.sc-user-input--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: rgba(86, 88, 103, 0.3);
  outline: none;
}

.sc-user-input--button:first-of-type {
  width: 40px;
}

.send-button {
  background-color: #FFCD46;
  color: white;
  margin:8px;
  width: 70px;
  height: 30px;
  border: none;
}


.sc-user-input--button button {
  cursor: pointer;
}

.sc-user-input--picker-wrapper {
  display: flex;
  flex-direction: column;
}

.sc-user-input.active {
  box-shadow: none;
  background-color: white;
  
}







.about-modal .modal-header{
    color:#fff;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
    }
    

    .about-modal .modal-content{
        border-radius: 0!important;
        border:none;
    }

    .about-modal .modal-header h5 {
        width:90%;
        text-align: center;
    }
    
    .about-modal .modal-header button {
        width:10%;
    }
    .about-modal .modal-header h5{
        font-size: 18px;
        }
    
    .about-modal .modal-header .close{
        color:#FFCD46;
        opacity: 1;
        text-shadow: none;
        font-size: x-large;
    }
    
    .about-modal .loginbutton{
        background-color: #FFCD46;
        border:none;
        border-radius: 0;
        margin:30px 0 20px 0;
        box-shadow: none;
        color: white;
    }
    
    .about-modal .loginbutton:hover{
        background-color: rgb(100, 145, 43);
        border:none;
        border-radius: 0;
        border-color:rgb(100, 145, 43);
        box-shadow: none;
        color: white;
    }
    
    .about-modal .modal-body{
        padding:3rem 3rem 0 2rem;
    }
    
    .about-modal .modal-body .form-group{
        justify-content: center;
    }
    
    .about-modal .forgot-pass-login{
        color:#ADB5BD!important;
       cursor: pointer;
       font-weight: 500;
    }
    
    
    .about-modal .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0;
        border-top: none;
        border-bottom-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
    }
    .about-modal .social-logins-login{
        display:flex;
        justify-content: space-around;
        border-top:1px solid #efefef;
        border-bottom:1px solid #efefef;
        padding:20px 0;
    }
    
    .about-modal .social-logins-login button {
        width:45%;
    }
    
    .about-modal input[type="email"], .about-modal input[type="password"], .about-modal input[type="text"],
    .about-modal select.form-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #000000;
      box-shadow: none;
      border-radius: 0;
    }
    
    .about-modal input[type="email"]:focus, .about-modal input[type="password"]:focus, .about-modal input[type="name"]:focus,
    .about-modal select.form-control:focus {
      box-shadow: none;
    }

    .login-with-google.btn-outline-primary{
        border-color:#ADB5BD;
        color:#ADB5BD;
        border-radius: 0;
    }

    .login-with-google.btn-outline-primary:hover{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
    }

    .login-with-fb.btn.btn-primary{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
        border-radius: 0;
    }

    .login-with-fb.btn.btn-primary:hover{
        border:1px solid #ADB5BD;
        background-color:#fff!important;
        color:#ADB5BD;
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
    }


    .email-icon-modal, .pass-icon-modal{
        position: absolute;
        top:12px;
        left:20px;
        color:#ADB5BD;
    }

    .eye-icon-modal{
        position: absolute;
        top:15px;
        right:25px;
        color:#FFCD46;
        
    }


.about-modal input[type="email"], .about-modal input[type="password"], .about-modal input[type="name"], .about-modal select.form-control, .about-modal .password-input{
    padding-left:40px;
}

.about-modal .form-control::-webkit-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::-moz-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control:-ms-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::-ms-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::placeholder {
    color:#CECECE
    ;

}

body { padding-right: 0 !important }

.about-modal .warning-modal .modal-content {
    border-radius: 20px !important;
    border: none;
    overflow: hidden;
}
.feedback-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.feedback-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.feedback-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.feedback-modal .modal-header button {
  width: 10%;
}
.feedback-modal .modal-header h5 {
  font-size: 18px;
}

.feedback-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.feedback-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.feedback-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.feedback-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.feedback-modal .modal-header button {
  width: 10%;
}
.feedback-modal .modal-header h5 {
  font-size: 18px;
}

.feedback-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.feedback-modal .send-button {
  background-color: #FFCD46;
  color: white;
  border: none;
  border-radius: 0;
  margin: 80px 0 0 0;
  box-shadow: none;
}

.feedback-modal .send-button:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
}

.feedback-modal .modal-body {
  padding: 3rem 5rem 3rem 5rem;
}

.feedback-modal .modal-body .form-group {
  justify-content: center;
}

.feedback-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.feedback-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.feedback-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.feedback-modal .social-logins-login button {
  width: 45%;
}

.feedback-modal input[type="email"],
.feedback-modal input[type="password"],
.feedback-modal input[type="text"],
.feedback-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.feedback-modal input[type="email"]:focus,
.feedback-modal input[type="password"]:focus,
.feedback-modal input[type="name"]:focus,
.feedback-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}

.feedback-modal input[type="email"],
.feedback-modal input[type="password"],
.feedback-modal input[type="name"],
.feedback-modal select.form-control,
.feedback-modal .password-input {
  padding-left: 40px;
}

.feedback-modal .send-button:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  color: #fff;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
}
.feedback-modal .send-button:hover,
.feedback-modal .send-button:active,
.feedback-modal .send-button:focus {
  color: #fff;
}

.feedback-modal .modal-body {
  padding: 3rem 5rem 3rem 5rem;
}

.feedback-modal .modal-body .form-group {
  justify-content: center;
}

.feedback-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.feedback-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.feedback-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.feedback-modal .social-logins-login button {
  width: 45%;
}

.feedback-modal input[type="email"],
.feedback-modal input[type="password"],
.feedback-modal input[type="text"],
.feedback-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.feedback-modal input[type="email"]:focus,
.feedback-modal input[type="password"]:focus,
.feedback-modal input[type="name"]:focus,
.feedback-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}

.feedback-modal input[type="email"],
.feedback-modal input[type="password"],
.feedback-modal input[type="name"],
.feedback-modal select.form-control,
.feedback-modal .password-input {
  padding-left: 40px;
}

.feedback-modal .form-control::-webkit-input-placeholder {
  color: #898989;
}

.feedback-modal .form-control::-moz-placeholder {
  color: #898989;
}

.feedback-modal .form-control:-ms-input-placeholder {
  color: #898989;
}

.feedback-modal .form-control::-ms-input-placeholder {
  color: #898989;
}

.feedback-modal .form-control::placeholder {
  color: #898989;
}

body {
  padding-right: 0 !important;
}

.reportreason-select {
  display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    border-radius: 8px;
    border: 1px solid #ececec;
    width: 96px;
    width: 100%;
    font-size: 18px;
}

.reportreason-select:disabled, .reportreason-select:disabled {     
  border: 2px solid #ececec; 
  background-color: #f1f1f1ce;  
  color: black;
}

.reportreason-select:focus, .reportreason-select:active {    
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  border: 2px solid #ffcd46;
  background-color: #fdfdfdce;
  width: 100%;
  font-size: 16px;
  color: black;
}




 .tos-modal .modal-header{
    color:#fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
    }
    

     .tos-modal .modal-content{
        border-radius: 0!important;
        border:none;
    }

     .tos-modal .modal-header h5 {
        width:90%;
        text-align: center;
    }
    
     .tos-modal .modal-header button {
        width:10%;
    }
     .tos-modal .modal-header h5{
        font-size: 18px;
        }
    
     .tos-modal .modal-header .close{
        color:#FFCD46;
        opacity: 1;
        text-shadow: none;
        font-size: x-large;
    }
    
     .tos-modal .loginbutton{
        background-color: #FFCD46;
        border:none;
        border-radius: 0;
        margin:30px 0 20px 0;
        box-shadow: none;
    color:white;

    }
    
     .tos-modal .loginbutton:hover{
        background-color: rgb(100, 145, 43);
        border:none;
        border-radius: 0;
        border-color:rgb(100, 145, 43);
        box-shadow: none;
    color:white;

    }
    
     .tos-modal .modal-body{
        padding:4rem 3rem 0 2rem;
    }
    
     .tos-modal .modal-body .form-group{
        justify-content: center;
    }
    
     .tos-modal .forgot-pass-login{
        color:#ADB5BD!important;
       cursor: pointer;
       font-weight: 500;
    }
    
    
     .tos-modal .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0;
        border-top: none;
        border-bottom-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
    }
     .tos-modal .social-logins-login{
        display:flex;
        justify-content: space-around;
        border-top:1px solid #efefef;
        border-bottom:1px solid #efefef;
        padding:20px 0;
    }
    
     .tos-modal .social-logins-login button {
        width:45%;
    }
    
     .tos-modal input[type="email"],  .tos-modal input[type="password"],  .tos-modal input[type="tel"],  .tos-modal input[type="text"],
     .tos-modal select.form-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #000000;
      box-shadow: none;
      border-radius: 0;
    }
    
     .tos-modal input[type="email"]:focus,  .tos-modal input[type="password"]:focus,  .tos-modal input[type="name"]:focus,
     .tos-modal select.form-control:focus {
      box-shadow: none;
    }

    .login-with-google.btn-outline-primary{
        border-color:#ADB5BD;
        color:#ADB5BD;
        border-radius: 0;
    }

    .login-with-google.btn-outline-primary:hover{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
    }

    .login-with-fb.btn.btn-primary{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
        border-radius: 0;
    }

    .login-with-fb.btn.btn-primary:hover{
        border:1px solid #ADB5BD;
        background-color:#fff!important;
        color:#ADB5BD;
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
    }


    .email-icon-modal, .pass-icon-modal{
        position: absolute;
        top:12px;
        left:20px;
        color:#ADB5BD;
    }

    .eye-icon-modal{
        position: absolute;
        top:15px;
        right:25px;
        color:#FFCD46;
        
    }


 .tos-modal input[type="email"],  .tos-modal input[type="password"], .tos-modal input[type="tel"],  .tos-modal input[type="name"],  .tos-modal select.form-control,  .tos-modal .password-input{
    padding-left:40px;
}

 .tos-modal .form-control::-webkit-input-placeholder {
    color:#CECECE
    ;

}

 .tos-modal .form-control::-moz-placeholder {
    color:#CECECE
    ;

}

 .tos-modal .form-control:-ms-input-placeholder {
    color:#CECECE
    ;

}

 .tos-modal .form-control::-ms-input-placeholder {
    color:#CECECE
    ;

}

 .tos-modal .form-control::placeholder {
    color:#CECECE
    ;

}

body { padding-right: 0 !important }




 .tos-modal .modal-header{
    color:#fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
    }
    

     .tos-modal .modal-content{
        border-radius: 0!important;
        border:none;
    }

     .tos-modal .modal-header h5 {
        width:90%;
        text-align: center;
    }
    
     .tos-modal .modal-header button {
        width:10%;
    }
     .tos-modal .modal-header h5{
        font-size: 18px;
        }
    
     .tos-modal .modal-header .close{
        color:#FFCD46;
        opacity: 1;
        text-shadow: none;
        font-size: x-large;
    }
    
     .tos-modal .loginbutton{
        background-color: #FFCD46;
        border:none;
        border-radius: 0;
        margin:30px 0 20px 0;
        box-shadow: none;
        color:white;

    }
    
     .tos-modal .loginbutton:hover{
        background-color: rgb(100, 145, 43);
        border:none;
        border-radius: 0;
        border-color:rgb(100, 145, 43);
        box-shadow: none;
    }
    
     .tos-modal .modal-body{
        padding:4rem 3rem 0 2rem;
    }
    
     .tos-modal .modal-body .form-group{
        justify-content: center;
    }
    
     .tos-modal .forgot-pass-login{
        color:#ADB5BD!important;
       cursor: pointer;
       font-weight: 500;
    }
    
    
     .tos-modal .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0;
        border-top: none;
        border-bottom-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
    }
     .tos-modal .social-logins-login{
        display:flex;
        justify-content: space-around;
        border-top:1px solid #efefef;
        border-bottom:1px solid #efefef;
        padding:20px 0;
    }
    
     .tos-modal .social-logins-login button {
        width:45%;
    }
    
     .tos-modal input[type="email"],  .tos-modal input[type="password"],  .tos-modal input[type="tel"],  .tos-modal input[type="text"],
     .tos-modal select.form-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #000000;
      box-shadow: none;
      border-radius: 0;
    }
    
     .tos-modal input[type="email"]:focus,  .tos-modal input[type="password"]:focus,  .tos-modal input[type="name"]:focus,
     .tos-modal select.form-control:focus {
      box-shadow: none;
    }

    .login-with-google.btn-outline-primary{
        border-color:#ADB5BD;
        color:#ADB5BD;
        border-radius: 0;
    }

    .login-with-google.btn-outline-primary:hover{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
    }

    .login-with-fb.btn.btn-primary{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
        border-radius: 0;
    }

    .login-with-fb.btn.btn-primary:hover{
        border:1px solid #ADB5BD;
        background-color:#fff!important;
        color:#ADB5BD;
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
    }


    .email-icon-modal, .pass-icon-modal{
        position: absolute;
        top:12px;
        left:20px;
        color:#ADB5BD;
    }

    .eye-icon-modal{
        position: absolute;
        top:15px;
        right:25px;
        color:#FFCD46;
        
    }


 .tos-modal input[type="email"],  .tos-modal input[type="password"], .tos-modal input[type="tel"],  .tos-modal input[type="name"],  .tos-modal select.form-control,  .tos-modal .password-input{
    padding-left:40px;
}

 .tos-modal .form-control::-webkit-input-placeholder {
    color:#CECECE
    ;

}

 .tos-modal .form-control::-moz-placeholder {
    color:#CECECE
    ;

}

 .tos-modal .form-control:-ms-input-placeholder {
    color:#CECECE
    ;

}

 .tos-modal .form-control::-ms-input-placeholder {
    color:#CECECE
    ;

}

 .tos-modal .form-control::placeholder {
    color:#CECECE
    ;

}

body { padding-right: 0 !important }
.settings-container {
  max-width: 1000px;
  margin: 80px auto;
}

.settings-container .title {
  text-align: left;
  margin-bottom: 20px;
  padding: 10px;
}

.settings-items {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: #FFCD46;
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
}

.settings-items .settings-item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
}

#freq-select {
  border: transparent;
}

.about:hover,
.feedback:hover,
.contact:hover,
.tos:hover,
.pp:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}

.deleteaccount:hover {
  cursor: pointer;
}

.contact-text {
  color: #a8a8a8;
}

.chev-right-contact {
  margin-left: 20px;
  color: #FFCD46;
}

.upload-pdf {
  width: 100%;
}
.settings-item-row .edit-post-btn button {
  background-color: #FFCD46 !important;
  color: white !important;
  font-size: 16px !important;
  border-radius: 0 !important;
  border: 0 !important;
}
.settings-item-row .edit-post-btn button {
  align-self: center;
}
.settings-item-row .upload-file-label {
  cursor: pointer;
  background-color: #FFCD46;
  color: white;
  font-size: 16px;
  padding: 10px 25px;
}
.upload-pdf .edit-post-btn button {
  background-color: #FFCD46 !important;
  color: white !important;
  font-size: 16px !important;
  border-radius: 0 !important;
  border: 0 !important;
}
.upload-pdf .upload-button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
}

/* input[type="file"] {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
} */

.save-settings {
  background-color: #FFCD46 !important;
  border: none !important;
  padding: 5px 45px !important;
  border-radius: 0 !important;
  color: white;
}
.save-settings:active,
.save-settings:visited,
.save-settings:link,
.save-settings:focus,
.save-settings:hover,
.save-settings:focus-within {
  background-color: #FFCD46 !important;
}
.settings-item-row.pdf:hover{
  text-decoration: none;
  background-color: #F7F7F7;
}
.settings-item-row.pdf p{
  color:#FFCD46;
}
.settings-item-row.pdf path{
  fill:#FFCD46;
}
.settings-item-row.delete:hover{
  text-decoration: none;
  background-color: #F7EAEA;
}
.feedback-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.feedback-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.feedback-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.feedback-modal .modal-header button {
  width: 10%;
}
.feedback-modal .modal-header h5 {
  font-size: 18px;
}

.feedback-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.feedback-modal .send-button {
  background-color: #FFCD46;
  border: none;
  border-radius: 0;
  margin: 80px 0 0 0;
  box-shadow: none;
  color:white;

}

.feedback-modal .send-button:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
}

.feedback-modal .modal-body {
  padding: 3rem 5rem 3rem 5rem;
}

.feedback-modal .modal-body .form-group {
  justify-content: center;
}

.feedback-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.feedback-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.feedback-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.feedback-modal .social-logins-login button {
  width: 45%;
}

.feedback-modal input[type="email"],
.feedback-modal input[type="password"],
.feedback-modal input[type="text"],
.feedback-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.feedback-modal input[type="email"]:focus,
.feedback-modal input[type="password"]:focus,
.feedback-modal input[type="name"]:focus,
.feedback-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}

.feedback-modal input[type="email"],
.feedback-modal input[type="password"],
.feedback-modal input[type="name"],
.feedback-modal select.form-control,
.feedback-modal .password-input {
  padding-left: 40px;
}

.feedback-modal .form-control::-webkit-input-placeholder {
  color: #898989;
}

.feedback-modal .form-control::-moz-placeholder {
  color: #898989;
}

.feedback-modal .form-control:-ms-input-placeholder {
  color: #898989;
}

.feedback-modal .form-control::-ms-input-placeholder {
  color: #898989;
}

.feedback-modal .form-control::placeholder {
  color: #898989;
}

body {
  padding-right: 0 !important;
}

/* #reportreason-select {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #000;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  color: #838383;
  font-size: 1.25rem;
} */

.carousel-item {
  /* background-color: lightgray; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}
.carousel-item img {
  width: 100%;
  vertical-align: middle;
}
.view-carousel-indicators li {
  width: 11px !important;
  height: 11px !important;
  border-radius: 50% !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border: 2px solid white !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.carousel .slide {
  width: 100% !important;
}
.carousel-indicators {
  width: 100% !important;
}
.carousel-indicators .active {
  border: 2px solid white !important;
  background-color: rgb(255, 255, 255) !important;
}

.carousel-control-next-icon {
  position: relative;
}

.carousel-control-next-icon:before {
  content: "";
  position: absolute;
  top: -9px;
  background-color: rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: -10px;
}

.carousel-control-prev-icon {
  position: relative;
}

.carousel-control-prev-icon:before {
  content: "";
  position: absolute;
  top: -9px;
  background-color: rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: -10px;
}

.reportbutton {
  position: absolute;
  right: 75px;
  z-index: 100;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}

.report-icon,
.goback-icon {
  color: #fff;
}

.report-icon:hover {
  color: red;
}

.backbutton {
  position: absolute;
  left: 75px;
  z-index: 100;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}

video::-webkit-media-controls-enclosure {
  padding: 0px;
  height: 70px;
  position: relative;
}

video::-webkit-media-controls-panel {
  position: absolute;
  left: 15%;
  /* margin: 0 auto; */
  bottom: 50px;
  width: 70%;
  opacity: 1 !important;
  display: -webkit-flex !important;
  margin-top: 50px;
  height: 70px;

  /* background-color: #34495e; */
  /* border-radius: 0 0 10px 10px; */
  background-image: none;
}
.carousel-item > img {
  /* width: auto; */
  max-height: 100%;
  object-fit: cover;
  width: 100% ;
}
.carousel-item,
.carousel-item.active {
  align-items: center;
          height: 100%;
}
.carousel-inner {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  text-align: center;
}
.carousel-inner video {
  align-items: center;
  width: 100%;
  max-height: 600px;
}
.col-sm-7{
  flex: 0 0 57.33333% !important;
}
.card-review-img {
}
.card-review-img img {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  object-fit: cover;

  padding: 0;
}

.card-review-img.col-sm-1 {
  padding: 0;
}
.card-review-description {
  color: #aaaaaa;
}
.user-review-card .card-header {
  border-bottom: 0;
}
.user-review-card {
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.card-review-user {
  color: #aaaaaa;
  font-size: 12px;
}
.user-review-card .dashboard-card-text {
  color: #585858;
  font-size: 16px;
}

.svg-inline--fa.fa-w-18.fa-star {
  margin-right: 2px;
}

.card-review-header.card-header {
  background-color: transparent;
}

.input-range__slider {
  background: #FFCD46;
  border: 1px solid #FFCD46;
  height: 1.5rem;
  width: 1.5rem;
}

.input-range__track--active {
  background: #d2e9b5;
}





.pdf-modal .modal-header{
    color:#fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
    }
    

    .pdf-modal .modal-content{
        border-radius: 0!important;
        border:none;
    }

    .pdf-modal .modal-header h5 {
        width:90%;
        text-align: center;
    }
    
    .pdf-modal .modal-header button {
        width:10%;
    }
    .pdf-modal .modal-header h5{
        font-size: 18px;
        }
    
    .pdf-modal .modal-header .close{
        color:#FFCD46;
        opacity: 1;
        text-shadow: none;
        font-size: x-large;
    }
    
    .pdf-modal .loginbutton{
        background-color: #FFCD46;
        color:white;
        border:none;
        border-radius: 0;
        margin:30px 0 20px 0;
        box-shadow: none;
    }
    
    .pdf-modal .loginbutton:hover{
        background-color: rgb(100, 145, 43);
        border:none;
        border-radius: 0;
        border-color:rgb(100, 145, 43);
        box-shadow: none;
    }
    
    .pdf-modal .modal-body{
        padding:3rem 3rem 0 2rem;
    }
    
    .pdf-modal .modal-body .form-group{
        justify-content: center;
    }
    
    .pdf-modal .forgot-pass-login{
        color:#ADB5BD!important;
       cursor: pointer;
       font-weight: 500;
    }
    
    
    .pdf-modal .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0;
        border-top: none;
        border-bottom-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
    }
    .pdf-modal .social-logins-login{
        display:flex;
        justify-content: space-around;
        border-top:1px solid #efefef;
        border-bottom:1px solid #efefef;
        padding:20px 0;
    }
    
    .pdf-modal .social-logins-login button {
        width:45%;
    }
    
    .pdf-modal input[type="email"], .pdf-modal input[type="password"], .pdf-modal input[type="text"],
    .pdf-modal select.form-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #000000;
      box-shadow: none;
      border-radius: 0;
    }
    
    .pdf-modal input[type="email"]:focus, .pdf-modal input[type="password"]:focus, .pdf-modal input[type="name"]:focus,
    .pdf-modal select.form-control:focus {
      box-shadow: none;
    }

    .login-with-google.btn-outline-primary{
        border-color:#ADB5BD;
        color:#ADB5BD;
        border-radius: 0;
    }

    .login-with-google.btn-outline-primary:hover{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
    }

    .login-with-fb.btn.btn-primary{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
        border-radius: 0;
    }

    .login-with-fb.btn.btn-primary:hover{
        border:1px solid #ADB5BD;
        background-color:#fff!important;
        color:#ADB5BD;
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
    }


    .email-icon-modal, .pass-icon-modal{
        position: absolute;
        top:12px;
        left:20px;
        color:#ADB5BD;
    }

    .eye-icon-modal{
        position: absolute;
        top:15px;
        right:25px;
        color:#FFCD46;
        
    }


.pdf-modal input[type="email"], .pdf-modal input[type="password"], .pdf-modal input[type="name"], .pdf-modal select.form-control, .pdf-modal .password-input{
    padding-left:40px;
}

.pdf-modal .form-control::-webkit-input-placeholder {
    color:#CECECE
    ;

}

.pdf-modal .form-control::-moz-placeholder {
    color:#CECECE
    ;

}

.pdf-modal .form-control:-ms-input-placeholder {
    color:#CECECE
    ;

}

.pdf-modal .form-control::-ms-input-placeholder {
    color:#CECECE
    ;

}

.pdf-modal .form-control::placeholder {
    color:#CECECE
    ;

}


.agree-button-modal-pdf.blockbutton{
    background-color: #FFCD46!important;
    border: none!important;
    padding: 10px 150px!important;
    border-radius: 0!important;
    box-shadow: none!important;
    width:100%;
    color:white;
}

.pdf-modal  .modal-footer{
    padding:50px 20px;
}

.pdf-modal .modalpdf-prev{
    background-color: #FFCD46;
    border:none;
    padding:5px 20px;
    margin-right: 10px;
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
    color:white;

}

.pdf-modal .modalpdf-next{
    background-color: #FFCD46;
    color:white;
    border:none;
    padding:5px 20px;
    margin-right: 10px;
    border-top-right-radius:5px;
    border-bottom-right-radius:5px

}

body { padding-right: 0 !important }
.about-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.about-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.about-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.about-modal .modal-header button {
  width: 10%;
}
.about-modal .modal-header h5 {
  font-size: 18px;
}

.about-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.about-modal .loginbutton {
  background-color: #FFCD46;
  border: none;
  border-radius: 0;
  margin: 30px 0 20px 0;
  box-shadow: none;
}

.about-modal .loginbutton:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
}

.about-modal .modal-body {
  padding: 3rem 3rem 0 3rem;
}

.about-modal .modal-body .form-group {
  justify-content: center;
}

.about-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.about-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.about-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.about-modal .social-logins-login button {
  width: 45%;
}

.about-modal input[type="email"],
.about-modal input[type="password"],
.about-modal input[type="text"],
.about-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.about-modal input[type="email"]:focus,
.about-modal input[type="password"]:focus,
.about-modal input[type="name"]:focus,
.about-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}

.about-modal input[type="email"],
.about-modal input[type="password"],
.about-modal input[type="name"],
.about-modal select.form-control,
.about-modal .password-input {
  padding-left: 40px;
}

.about-modal .form-control::-webkit-input-placeholder {
  color: #cecece;
}

.about-modal .form-control::-moz-placeholder {
  color: #cecece;
}

.about-modal .form-control:-ms-input-placeholder {
  color: #cecece;
}

.about-modal .form-control::-ms-input-placeholder {
  color: #cecece;
}

.about-modal .form-control::placeholder {
  color: #cecece;
}

body {
  padding-right: 0 !important;
}

.post-info-main-container {
  padding: 0 30px !important;
}
.post-info-container {
  display: flex;

  border-bottom: 1px solid #efefef;
  padding: 10px 0;
}
.post-info-container.main-one .right-side {
  display: flex;
  flex-direction: row;
}
.no-wrap-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap !important;
}
.post-info-container.main-one .right-side .right-side-one {
  width: 70%;
}
.post-info-container.main-one .right-side .right-side-two {
  width: 30%;
}
.post-info-container .left-side {
  width: 30%;
}
.post-info-container .addons-side-left {
  width: 25%;
  padding: 10px;
}
.post-info-container .right-side {
  width: 100%;
  padding: 10px;
}
.post-info-container .left-side .left-side-pf {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  object-fit: cover;
  padding: 0;
}
/* .post-info-container .left-side .left-side-icon {
  width: 100%;
  padding: 20px;
} */
.post-info-container .post-title-title {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
}
.post-info-container .post-title {
  margin-bottom: 10px;
  font-weight: bold;
}
.no-margin {
  margin: 0 !important;
}
.post-info-container .post-title-username {
  color: #55575F;
  font-size: 18px;
  font-weight: 600;
}
.post-title-username {
  color: #55575F;
  font-size: 18px;
  font-weight: 600;
}
.post-info-container .user-id {
  margin-left: 20px;
}
.post-info-container .rates-container {
  display: flex;
  justify-content: space-between;
}
.post-info-container .ratePrice {
  color: #000;
  font-weight: 500;
}
.post-info-container .start-date {
  color: #FFCD46;
  font-weight: 600;
}
.post-info-container .rental-date-container {
  display: flex;
  justify-content: space-between;
}
.single-post-navs {
  border: none;
  background-color: transparent;
  margin-bottom: 20px !important;
}
.single-post-navs .nav-item {
  border: none !important;
  margin-right: 50px !important;
}
.nav-item {
  cursor: pointer;
}
.nav-item a:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
.nav-item .nav-link {
  font-weight: 500;
  font-size: 25px;
}
.nav-tabs .nav-link.active {
  border-color: #FFCD46 !important;
  border-bottom: 4px solid;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  color: #FFCD46 !important;
}
.post-info-container .pickup-or-delivary-container {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}
.pickup-or-delivary-container .pickup-text,
.pickup-or-delivary-container .delivery-text,
.pickup-address-container {
  padding-top: 7px;
}
.pickup-address-container span {
  color: #FFCD46;
}
/* input[type="text"],
select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
} */
input[type="text"]:focus,
select.form-control:focus {
  box-shadow: none;
}
.delivery-address-container input::-webkit-input-placeholder {
  color: red;
  font-size: 14px;
  opacity: 0.7;
}
.delivery-address-container input::-moz-placeholder {
  color: red;
  font-size: 14px;
  opacity: 0.7;
}
.delivery-address-container input:-ms-input-placeholder {
  color: red;
  font-size: 14px;
  opacity: 0.7;
}
.delivery-address-container input::-ms-input-placeholder {
  color: red;
  font-size: 14px;
  opacity: 0.7;
}
.delivery-address-container input::placeholder {
  color: red;
  font-size: 14px;
  opacity: 0.7;
}
.input-range__label.input-range__label--value {
  color: #FFCD46;
}
.total-amout-container {
  background-color: #f9fcf4;
  padding-top: 10px;
}
.addon-cards-container {
  display: flex;
}
.addon-cards-container0 {
  width: 730px;
  overflow-x: auto;
  white-space: nowrap;
}
.addon-cards-container .card {
  margin: 10px 20px;
  max-height: 335px;
}
.add-on-img {
  max-width: 300px;
  height: 240px;
  overflow: hidden;
}
.add-ons-button {
  background-color: #FFCD46 !important;
  border: none !important;
  padding: 5px 45px !important;
  border-radius: 0 !important;
  color: white;
}
.add-ons-button:active,
.add-ons-button:visited,
.add-ons-button:link,
.add-ons-button:focus,
.add-ons-button:focus-within {
  background-color: #FFCD46 !important;
  border: none !important;
  padding: 5px 45px !important;
  border-radius: 0 !important;
  color: white;
}
.price-on-addons {
  position: absolute;
  top: 180px;
  right: 0;
  background-color: #FFCD46;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 5px 15px;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.postview-submitbutton.post-page-continue,
.postview-submitbutton.post-page-continue:active,
.postview-submitbutton.post-page-continue:visited,
.postview-submitbutton.post-page-continue:link,
.postview-submitbutton.post-page-continue:focus,
.postview-submitbutton.post-page-continue:focus-within,
.postview-submitbutton.post-page-continue:active:focus {
  background-color: #FFCD46 !important;
  border: none !important;
  padding: 10px 45px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: white;
}
.postview-submitbutton.post-page-cancel,
.postview-submitbutton.post-page-cancel:active,
.postview-submitbutton.post-page-cancel:visited,
.postview-submitbutton.post-page-cancel:link,
.postview-submitbutton.post-page-cancel:focus,
.postview-submitbutton.post-page-cancel:focus-within,
.postview-submitbutton.post-page-cancel:active:focus {
  background-color: red !important;
  border: none !important;
  padding: 10px 45px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
/*history tab*/
.history-item-container {
  display: flex;
  height: auto;
  width: 100%;
  padding: 10px;
  border: 1px solid #efefef;
}
.history-item-container:hover {
  background-color: rgba(255, 204, 29, 0.1);
  /* background-color: #E7B10A; */
}
.history-item-container .leftside-pic {
  width: 30%;
}
.history-item-container .rightside-info {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.rightside-info .info2 {
  display: flex;
  padding: 5px 0;
}
.rightside-info .info2 .info-prof-pic .left-side-pf {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  padding: 0;
}
.rightside-info .username-of-renter {
  margin: 0;
}
.rightside-info .right-side {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  padding-left: 10px;
}
.rightside-info .info1,
.rightside-info .info2,
.rightside-info .info3,
.rightside-info .info4 {
  margin: 2px 0;
}
.info3 .rented-date {
  margin-bottom: 3px;
}
.left-side.newicons{
  text-align: center;
}
.left-side.newicons .left-side-icon{
  width: 24px;
}
.timedatepicker-cont.col-sm-6 {
  text-align: right;
}
.timedatepicker-cont.col-sm-6 .DayPickerInput  {
  width:100%;
  text-align: right;
}
.timedatepicker-cont.col-sm-6 .DayPickerInput input{
  text-align: center;
  width:100%;
}
.DayPickerInput-Overlay {
 
  right: 0 !important;

}
.profile-img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
  background-color: #ECECEC;
  font-weight: bold;
  font-size: 20px;
}
.post-title {
  font-size:  50px;
  color: #000;
  font-weight: 700;
}
.post-price {
  font-size:  32px;
  color: #000;
  font-weight: 700;
}
.post-subtitle {
font-weight: 600;
font-size: 20px;
line-height: 150%;
color: #E7B10A;
}

.post-label {
  color: #000;
  font-size: 18px;
}
 .view-post-tabs .nav-tabs .nav-link.active {
    color: #FFCD46 !important;
    /* background-color: #fff; */
    /* border-bottom-color: #dee2e6 #dee2e6 #fff; */
    border: 0 !important;
    border-bottom: 1px solid #FFCD46 !important;
}

.view-post-tabs .nav-tabs .nav-item {
    font-size: 21px;
    cursor: pointer;
    color: #585858;
}

.view-post-tabs .nav-tabs {
    border-bottom: 0;
}

.button>.inner>button {
    background: #FFCD46 !;
}
.my-map-container {
    position: relative;
    height: 500px;
    overflow: hidden;
}
.autocomplete-container input {
    margin-bottom: 20px;
}
.location-search-input {
    width: 100%;
}
.map {
    /* width: 500px;
    max-width: 500px !important; */
    /* height: 100%; */
    max-height: 400px !important;
    overflow: hidden;
}


/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

 .rdt {
    position: relative;
  }
  .rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
  }
  .rdtOpen .rdtPicker {
    display: block;
  }
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }
  
  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }
  
  .rdtPicker table {
    width: 100%;
    margin: 0;
  }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }
  .rdtPicker td {
    cursor: pointer;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }
  .rdtPicker td.rdtToday {
    position: relative;
  }
  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  
  .rdtPicker td span.rdtOld {
    color: #999999;
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
  }
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }
  .rdtPicker th.rdtSwitch {
    width: 100px;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }
  
  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
  }
  
  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }
  
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }
  .rdtPicker button:hover {
    background-color: #eee;
  }
  
  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }
  
  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }
  
  .rdtCounters {
    display: inline-block;
  }
  
  .rdtCounters > div {
    float: left;
  }
  
  .rdtCounter {
    height: 100px;
  }
  
  .rdtCounter {
    width: 40px;
  }
  
  .rdtCounterSeparator {
    line-height: 100px;
  }
  
  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
  
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  .rdtCounter .rdtBtn:hover {
    background: #eee;
  }
  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
  
  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }
  
  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
  
  .rdtTime td {
    cursor: default;
  }
.edit-post .form-control {
    border: 0 !important;
    border-bottom: 1px solid black !important;
    border-radius: 0 !important;
    box-shadow: none;
}
.edit-post .input-group-text {
    padding: 0 .75rem 0 0 !important;
    font-size: 2rem !important;
    font-weight: 600 !important;
    color: #FFCD46 !important;
    text-align: left !important;
    background-color: #e9ecef00 !important; 
    border: 0 !important;
    border-radius: 0 !important;
    
} 
.edit-post .edit-post-btn button{
    background-color: #FFCD46 !important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
}
.edit-post .edit-post-submit-btn {
    background-color: #FFCD46 !important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    width: 100% !important;
}
.edit-post .edit-post-submit-btn:active {
    background-color: #FFCD46 !important;
    color: white !important;
    /* font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    width: 100% !important; */
}
.edit-post label {
    color: #AAAAAA;
    font-size: 13px;
    font-weight: 400;
    width: 100%;
}
/* .edit-post .form-control {
    padding: 1.375rem .75rem;
} */
.edit-post .form-group {
    margin-bottom: 20px ;
}
.edit-post input[type="file"] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    /* // alternative to pointer-events, compatible with all browsers, just make it impossible to find */
    width: 1px;
    height: 1px;
  }
.edit-post .upload-file-label {
    cursor: pointer;
    background-color: #FFCD46;
    color: white;
    font-size: 16px;
    padding: 10px 25px;
}
.edit-post .submit-button  .btn-secondary:active, .edit-post .submit-button .edit-post-submit-btn:active {
    color: #fff;
    /* filter:brightness(85%); */
    background: #FFCD46 !important;
}
.edit-post-submit-btn.submit-button.btn.btn-secondary:focus {
    color: #fff;
    -webkit-filter:brightness(85%);
            filter:brightness(85%);
    background-color: #FFCD46 !important;
}

.image-upload-container .upload-btn.upload.upload-images-btn, .image-upload-container .upload-btn.upload.upload-images-btn:visited{
    background-color: #FFCD46 !important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    border: 0 !important;
    /* filter:brightness(100%); */
}

.image-upload-container .upload-reset-btn.upload.upload-images-btn, .image-upload-container .upload-reset-btn.upload.upload-images-btn:visited{
    background-color: #454642!important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    border: 0 !important;
    -webkit-filter:brightness(100%);
            filter:brightness(100%);
}


.video-upload-container .upload-btn.upload.upload-images-btn{
    background-color: #FFCD46 !important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    width: 100% !important;
}

.upload-images-button-container .upload-reset-btn.upload.upload-images-btn, .upload-images-button-container .upload-reset-btn.upload.upload-images-btn:visited{
    background-color: #FFCD46!important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    -webkit-filter:brightness(100%);
            filter:brightness(100%);
    padding-left:25px;
    padding-right:25px;
}

.timedatepicker-cont .rdt input {
    text-align: center;
} 

.price-on-addons{
    position: absolute;
    top: 20px;
    right: 0;
    background-color: #FFCD46;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding:5px 15px;
    color:#fff;
    text-shadow: 0 2px 4px rgba(0,0,0,0.10);

  }


  /*for addons section*/


  
.post-info-container{
    display:flex;
    border-bottom: 1px solid #efefef;
    padding:10px 0;
   
}

.post-info-container.main-one .right-side{
display: flex;

}

.post-info-container.main-one .right-side .right-side-one{
    width:70%
}


.post-info-container.main-one .right-side .right-side-two{
    width:30%
}

.post-info-container .left-side{
    width:10%;
}

.post-info-container .addons-side-left{
    width:25%;
    padding:10px;
}


.post-info-container .right-side{
    width:100%;
    padding:10px;
}

.post-info-container .left-side .left-side-pf{
    border-radius: 100%;
    width: 70px;
    height: 70px;
    object-fit: cover;
  
    padding: 0;
}

/* .post-info-container .left-side .left-side-icon{
    width:100%;
    padding:20px;
} */

.post-info-container .post-title{
    margin-bottom: 10px;
}

.no-margin{
    margin:0!important;
}


.post-info-container .post-title-username{
    color:#B1B1B1;
    

}
.post-info-container .user-id{
    margin-left:20px;
}


.post-info-container .rates-container{
    display: flex;
    justify-content:space-between;
   
}

.post-info-container  .ratePrice{
    color:#000;
}

.post-info-container .start-date{
    color:#FFCD46;
    font-weight: 600;
}

.post-info-container .rental-date-container{
    display:flex;
    justify-content: space-between;
}
.add-ons-button-edit-post{
    color:#fff;
    padding:5px 20px;
    box-shadow: none;
    text-shadow: none;
    border: none;
    width: 100%;    
}

  .rentedbyitself{
   padding-top:20px;
  }

  .form-control::-webkit-input-placeholder{
      font-weight: 300;
      letter-spacing: 1px;
      color:rgba(0,0,0,0.1);
  }

  .form-control::-moz-placeholder{
      font-weight: 300;
      letter-spacing: 1px;
      color:rgba(0,0,0,0.1);
  }

  .form-control:-ms-input-placeholder{
      font-weight: 300;
      letter-spacing: 1px;
      color:rgba(0,0,0,0.1);
  }

  .form-control::-ms-input-placeholder{
      font-weight: 300;
      letter-spacing: 1px;
      color:rgba(0,0,0,0.1);
  }

  .form-control::placeholder{
      font-weight: 300;
      letter-spacing: 1px;
      color:rgba(0,0,0,0.1);
  }

  .change-button{
      background-color: #FFCD46;
      color:#fff;
      border-radius: 0;
      box-shadow: none;
      border:none;
      padding:5px 20px;
  }
  .final-addon-container{
    width: 850px;
  }
  @media only screen and (max-width: 1500px) {
        .final-addon-container{
            width: 550px;
        }
    }
    
    .input-style-post option {
        padding: 15px !important;
    }
    
    .post-content-description {
            /* font-size: 18px; */
        padding: 0px 30px 30px;
        position: absolute;
        right: 0%;
        overflow-y: auto;
        /* height: 450px; */
    height: 73vh;
        }
    
    @media only screen and (max-width: 1400px) {
    .post-content-description{
    padding: 0px 30px 30px;
    position: absolute;
    right: 0%;
    overflow-y: auto;
    height: 63vh;
    /* height: 450px; */
}
}




.about-modal .modal-header{
    color:#fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
    }
    

    .about-modal .modal-content{
        border-radius: 0!important;
        border:none;
    }

    .about-modal .modal-header h5 {
        width:90%;
        text-align: center;
    }
    
    .about-modal .modal-header button {
        width:10%;
    }
    .about-modal .modal-header h5{
        font-size: 18px;
        }
    
    .about-modal .modal-header .close{
        color:#FFCD46;
        opacity: 1;
        text-shadow: none;
        font-size: x-large;
    }
    
    .about-modal .loginbutton{
        background-color: #FFCD46;
        color:white;
        border:none;
        border-radius: 0;
        margin:30px 0 20px 0;
        box-shadow: none;
    }
    
    .about-modal .loginbutton:hover{
        background-color: rgb(100, 145, 43);
        border:none;
        border-radius: 0;
        border-color:rgb(100, 145, 43);
        box-shadow: none;
    }
    
    .about-modal .modal-body{
        padding:3rem 3rem 0 3rem;
    }
    
    .about-modal .modal-body .form-group{
        justify-content: center;
    }
    
    .about-modal .forgot-pass-login{
        color:#ADB5BD!important;
       cursor: pointer;
       font-weight: 500;
    }
    
    
    .about-modal .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0;
        border-top: none;
        border-bottom-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
    }
    .about-modal .social-logins-login{
        display:flex;
        justify-content: space-around;
        border-top:1px solid #efefef;
        border-bottom:1px solid #efefef;
        padding:20px 0;
    }
    
    .about-modal .social-logins-login button {
        width:45%;
    }
    
    .about-modal input[type="email"], .about-modal input[type="password"], .about-modal input[type="text"],
    .about-modal select.form-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #000000;
      box-shadow: none;
      border-radius: 0;
    }
    
    .about-modal input[type="email"]:focus, .about-modal input[type="password"]:focus, .about-modal input[type="name"]:focus,
    .about-modal select.form-control:focus {
      box-shadow: none;
    }

    .login-with-google.btn-outline-primary{
        border-color:#ADB5BD;
        color:#ADB5BD;
        border-radius: 0;
    }

    .login-with-google.btn-outline-primary:hover{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
    }

    .login-with-fb.btn.btn-primary{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
        border-radius: 0;
    }

    .login-with-fb.btn.btn-primary:hover{
        border:1px solid #ADB5BD;
        background-color:#fff!important;
        color:#ADB5BD;
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
    }


    .email-icon-modal, .pass-icon-modal{
        position: absolute;
        top:12px;
        left:20px;
        color:#ADB5BD;
    }

    .eye-icon-modal{
        position: absolute;
        top:15px;
        right:25px;
        color:#FFCD46;
        
    }


.about-modal input[type="email"], .about-modal input[type="password"], .about-modal input[type="name"], .about-modal select.form-control, .about-modal .password-input{
    padding-left:40px;
}

.about-modal .form-control::-webkit-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::-moz-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control:-ms-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::-ms-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::placeholder {
    color:#CECECE
    ;

}

body { padding-right: 0 !important }




.about-modal .loginbutton{
    background-color: #FFCD46;
    color:white;
    border:none;
    border-radius: 0;
    margin:10px 0 10px 0;
    box-shadow: none;
    text-shadow: 0 2px 4px rgba(0,0,0,0.10);
    
}

.about-modal .loginbutton:hover{
    background-color: rgb(100, 145, 43);
    border:none;
    border-radius: 0;
    border-color:rgb(100, 145, 43);
    box-shadow: none;
}





.about-modal .modal-header{
    color:#fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
    }
    

    .about-modal .modal-content{
        border-radius: 0!important;
        border:none;
    }

    .about-modal .modal-header h5 {
        width:90%;
        text-align: center;
    }
    
    .about-modal .modal-header button {
        width:10%;
    }
    .about-modal .modal-header h5{
        font-size: 18px;
        }
    
    .about-modal .modal-header .close{
        color:#FFCD46;
        opacity: 1;
        text-shadow: none;
        font-size: x-large;
    }
    
    .about-modal .loginbutton{
        background-color: #FFCD46;
        border:none;
        border-radius: 0;
        margin:30px 0 20px 0;
        box-shadow: none;
        color:white;
    }
    
    .about-modal .loginbutton:hover{
        background-color: rgb(100, 145, 43);
        border:none;
        border-radius: 0;
        border-color:rgb(100, 145, 43);
        box-shadow: none;
    }
    
    .about-modal .modal-body{
        padding:3rem 3rem 0 3rem;
    }
    
    .about-modal .modal-body .form-group{
        justify-content: center;
    }
    
    .about-modal .forgot-pass-login{
        color:#ADB5BD!important;
       cursor: pointer;
       font-weight: 500;
    }
    
    
    .about-modal .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0;
        border-top: none;
        border-bottom-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
    }
    .about-modal .social-logins-login{
        display:flex;
        justify-content: space-around;
        border-top:1px solid #efefef;
        border-bottom:1px solid #efefef;
        padding:20px 0;
    }
    
    .about-modal .social-logins-login button {
        width:45%;
    }
    
    .about-modal input[type="email"], .about-modal input[type="password"], .about-modal input[type="text"],
    .about-modal select.form-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #000000;
      box-shadow: none;
      border-radius: 0;
    }
    
    .about-modal input[type="email"]:focus, .about-modal input[type="password"]:focus, .about-modal input[type="name"]:focus,
    .about-modal select.form-control:focus {
      box-shadow: none;
    }

    .login-with-google.btn-outline-primary{
        border-color:#ADB5BD;
        color:#ADB5BD;
        border-radius: 0;
    }

    .login-with-google.btn-outline-primary:hover{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
    }

    .login-with-fb.btn.btn-primary{
        border-color:#ADB5BD;
        background-color:#ADB5BD;
        color:#fff;
        border-radius: 0;
    }

    .login-with-fb.btn.btn-primary:hover{
        border:1px solid #ADB5BD;
        background-color:#fff!important;
        color:#ADB5BD;
        -webkit-filter: brightness(100%);
                filter: brightness(100%);
    }


    .email-icon-modal, .pass-icon-modal{
        position: absolute;
        top:12px;
        left:20px;
        color:#ADB5BD;
    }

    .eye-icon-modal{
        position: absolute;
        top:15px;
        right:25px;
        color:#FFCD46;
        
    }


.about-modal input[type="email"], .about-modal input[type="password"], .about-modal input[type="name"], .about-modal select.form-control, .about-modal .password-input{
    padding-left:40px;
}

.about-modal .form-control::-webkit-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::-moz-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control:-ms-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::-ms-input-placeholder {
    color:#CECECE
    ;

}

.about-modal .form-control::placeholder {
    color:#CECECE
    ;

}

body { padding-right: 0 !important }




.about-modal .loginbutton{
    background-color: #FFCD46;
    color:white;
    border:none;
    border-radius: 0;
    margin:10px 0 10px 0;
    box-shadow: none;
    text-shadow: 0 2px 4px rgba(0,0,0,0.10);
    
}

.about-modal .loginbutton:hover{
    background-color: rgb(100, 145, 43);
    border:none;
    border-radius: 0;
    border-color:rgb(100, 145, 43);
    box-shadow: none;
}

.my-map-container {
    
    height: 500px;
    overflow: hidden;
}
.autocomplete-container input {
    margin-bottom: 20px;
}
.location-search-input {
    width: 100%;
    padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid #ececec; 
  
  font-size: 16px;
  color: black;
}
.map {
   
    max-width: 100%;
    height: 100%;
    max-height: 400px !important;
    overflow: hidden;
}


.edit-own-post-menu{
width: calc(100% + 30px);
    height: 80px;
    margin-left: -15px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
}

.edit-own-post-menu .edit-button{
    background-color: #FFCD46!important;
    border: none!important;
    padding: 10px 45px!important;
    border-radius: 0!important;
    box-shadow: none!important;
    color:white;

}


.edit-button:active,.edit-button:visited, .edit-button:link, .edit-button:focus, .edit-button:focus-within{
    background-color:#FFCD46!important;
    border:none!important;
    padding: 10px 45px!important;
    border-radius: 0!important;
    -webkit-filter:brightness(100%);
            filter:brightness(100%);
    background: #FFCD46!important;
    color:white;

}

.edit-own-post-menu .out-of-service-button{
    background-color:  #E24A4A!important;
    border: none!important;
    padding: 10px 45px!important;
    border-radius: 0!important;
    box-shadow: none!important;
}

.edit-own-post-menu .in-service-button{
    background-color:  #FFCD46!important;
    border: none!important;
    padding: 10px 45px!important;
    border-radius: 0!important;
    box-shadow: none!important;
    color:white;

}


.buttonrow button{
    margin-right:20px;
}

.buttonrowtwo{
    display:flex;
    justify-content: flex-end;
}


.notlogged-in{
    width: 40%;
    padding: 100px 30px;
    text-align: center;
    background-color: rgb(138, 197, 63);
    /* height: 100%; */
    margin-top: 20vh;
    margin: 20vh auto;
    border-radius: 10px;
}
.renting-nav{
    width:100%;
    display:flex;
    padding-left:50px;
    padding-bottom: 5px;
    list-style: none;
    background-color: transparent;
    margin-top:50px;
   
}

.renting-nav span {
    margin-bottom: -1px;
    margin-right: 50px!important;
    cursor: pointer;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    font-weight: 500;
    font-size: 25px;
    color: #ADB5BD!important;
}

.renting-nav .active {
    /* border-bottom: 4px solid #FFCD46; */
    border-right: none!important;
    border-left: none!important;
    border-top: none!important;
    color: #000!important;
}

.rentingout-list-content, .renting-list-content{
    padding:30px;
}
/* .dashboard-card {
  width: 100%;
  min-height: 320px;
  margin-bottom: 40px;
  background-color: white;
  box-shadow: 0px 8px 26px #00000055;
} */
.dashboard-card-body {
  padding: 10px;
  line-height: 1.4;
}
.renting-card-title {
  color: black;
  font-size: 18px;
  font-weight: bold;
}
.dashboard-card-text {
  font-size: 16px;
  color: #374151;
}
.card-img {
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-img img {
  width: 100%;
  vertical-align: middle;
}
.card-img-header {
  padding: 0;
}

.rentingout-pf-section-container {
  display: flex;
  padding-bottom: 10px;
}

.rentingout-pf-section-container .first {
  width: 10%;
}

.rentingout-pf-section-container .first img {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  padding: 0;
}

.rentingout-pf-section-container .second {
  width: 90%;
  display: flex;
  align-items: center;
}

.info-in-renting {
  font-size: 1.25em;
}

.info-in-renting p {
  width: 500px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title-rentingout {
  display: inline-block;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.info-in-renting span {
  padding-left: 10px;
}

.renting-status {
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 10px;
}
@media only screen and (max-width: 1500px) {
  .rentingout-pf-section-container .first{
  width:15%;}
  .rentingout-pf-section-container .second{
  width:75%;}
  }
.edit-profile-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.edit-profile-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.edit-profile-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.edit-profile-modal .modal-header button {
  width: 10%;
}
.edit-profile-modal .modal-header h5 {
  font-size: 18px;
}

.edit-profile-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.edit-profile-modal .loginbutton {
  background-color: #FFCD46;
  border: none;
  border-radius: 0;
  margin: 30px 0 20px 0;
  box-shadow: none;
  color:white;
}

.edit-profile-modal .loginbutton:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
  color:white;
}

.edit-profile-modal .modal-body {
  padding: 2rem 3rem 0 3rem;
}

.edit-profile-modal .modal-body .form-group {
  justify-content: center;
}

.edit-profile-modal .forgot-pass-login {
  color: #ADB5BD !important;
  font-weight: 500;
  cursor: pointer;
}

.edit-profile-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.edit-profile-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.edit-profile-modal .social-logins-login button {
  width: 45%;
}

.edit-profile-modal input[type="email"],
.edit-profile-modal input[type="password"],
.edit-profile-modal input[type="text"],
.edit-profile-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.edit-profile-modal input[type="email"]:focus,
.edit-profile-modal input[type="password"]:focus,
.edit-profile-modal input[type="name"]:focus,
.edit-profile-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
  cursor: pointer;
}

.edit-profile-modal input[type="email"],
.edit-profile-modal input[type="password"],
.edit-profile-modal input[type="text"],
.edit-profile-modal select.form-control,
.edit-profile-modal .password-input {
}

.edit-profile-modal .form-control::-webkit-input-placeholder {
  color: #898989;
}

.edit-profile-modal .form-control::-moz-placeholder {
  color: #898989;
}

.edit-profile-modal .form-control:-ms-input-placeholder {
  color: #898989;
}

.edit-profile-modal .form-control::-ms-input-placeholder {
  color: #898989;
}

.edit-profile-modal .form-control::placeholder {
  color: #898989;
}

.edit-profile-modal label {
  color: #898989;
  padding-left: 25px;
  font-size: 1rem;
}

.profile-pic-container .edit-pf {

  height: 150px;
  object-fit: cover;
  padding: 0;
  border-radius: 100%;
}

.profile-pic-container {
  text-align: center;
}

.editprofile-buttons {
  display: flex;
  justify-content: space-between;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.editprofile-buttons .cancel-btn,
.editprofile-buttons .save-btn {
  padding: 10px 60px;
  margin: 50px 0;
}

.edit-profile-modal .editprofile-buttons .save-btn {
  background-color: #FFCD46;
  color:white;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.edit-profile-modal .editprofile-buttons .save-btn:hover {
  background-color: #FFCD46;
  color:white;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.edit-profile-modal .editprofile-buttons .cancel-btn.cancel-editp {
  color: #FFCD46;
  border-color: #FFCD46;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent !important;
}
.edit-profile-modal .editprofile-buttons .cancel-editp.cancel-btn:hover {
  color: #FFCD46 !important;
  border-color: #FFCD46 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 1px solid #FFCD46 !important;
}

body {
  padding-right: 0 !important;
}

.other-user-prof-container {
  width: 100%;
  padding: 0.5em 2em 0.5em 1.5em;
}

.other-user-prof-container .user-info-container {
  display: flex;
  justify-content: space-between;
}

.other-user-prof-container .container {
  max-width: 100% !important;
  display: flex;
  justify-content: space-around;
}

.onee {
  width: 50%;
  display: flex;
}

.twoo {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  padding: 30px 0;
}

.post-info-container {
  display: flex;
  padding: 10px 0;
}

.post-info-container.main-one .right-side {
  display: flex;
}

.post-info-container.main-one .right-side .right-side-one {
  width: 70%;
  margin-left: 15px;
}

.post-info-container.main-one .right-side .right-side-two {
  width: 30%;
}

.post-info-container .left-side {
  width: 10%;
}

.post-info-container .right-side {
  width: 90%;
  padding: 10px;
}

.post-info-container .left-side .left-side-pf {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  object-fit: cover;

  padding: 0;
}

/* .post-info-container .left-side .left-side-icon {
  width: 100%;
  padding: 20px;
} */

.post-info-container .post-title {
  margin-bottom: 10px;
}

.no-margin {
  margin: 0 !important;
}

.post-info-container .post-title-username {
  color: #b1b1b1;
}
.post-info-container .user-id {
  margin-left: 20px;
}

.post-info-container .rates-container {
  display: flex;
  justify-content: space-between;
}

.post-info-container .ratePrice {
  color: #000;
}

.post-info-container .start-date {
  color: #FFCD46;
  font-weight: 600;
}

.post-info-container .rental-date-container {
  display: flex;
  justify-content: space-between;
}

.message-user-btn {
  background-color: #FFCD46 !important;
  padding: 10px 45px !important;
  border: none !important;
  border-radius: 0 !important;
    color:white;

}

.message-user-btn.btn.btn-secondary.btn-lg:active,
.message-user-btn.btn.btn-secondary.btn-lg:focus,
.message-user-btn.btn.btn-secondary.btn-lg:focus-within,
.message-user-btn.btn.btn-secondary.btn-lg:visited {
  background-color: #FFCD46 !important;
  background: #FFCD46 !important;
  opacity: 1 !important;
  -webkit-filter: brightness(100%) !important;
          filter: brightness(100%) !important;
    color:white;

}

.other-user-prof-container{
    width:100%;
    padding:0.5em 2em 0.5em 1.5em; 
}



.other-user-prof-container .user-info-container{
    display: flex;
    justify-content: space-between;
}

.other-user-prof-container .container {
    max-width: 100%!important;
    display: flex;
    justify-content: space-around;

}

.onee{
    width:50%;
    display: flex;
}

.twoo{
    width:50%;
    display: flex;
    justify-content:flex-end;
    padding:30px 0;
}

.post-info-container{
    display:flex;
    padding:10px 0;
   
}

.post-info-container.main-one .right-side{
display: flex;

}

.post-info-container.main-one .right-side .right-side-one{
    width:70%
}


.post-info-container.main-one .right-side .right-side-two{
    width:30%
}

.post-info-container .left-side{
    width:10%;
}

.post-info-container .right-side{
    width:90%;
    padding:10px;
}

.post-info-container .left-side .left-side-pf{
    border-radius: 100%;
    width: 70px;
    height: 70px;
    object-fit: cover;
  
    padding: 0;
}

/* .post-info-container .left-side .left-side-icon{
    width:100%;
    padding:20px;
} */

.post-info-container .post-title{
    margin-bottom: 10px;
}

.no-margin{
    margin:0!important;
}


.post-info-container .post-title-username{
    color:#B1B1B1;
    

}
.post-info-container .user-id{
    margin-left:20px;
}


.post-info-container .rates-container{
    display: flex;
    justify-content:space-between;
   
}

.post-info-container  .ratePrice{
    color:#000;
}

.post-info-container .start-date{
    color:#FFCD46;
    font-weight: 600;
}

.post-info-container .rental-date-container{
    display:flex;
    justify-content: space-between;
}




.message-user-btn{
    background-color: #FFCD46!important;
    padding:10px 120px!important;
    border:none!important;
    border-radius: 0!important;
    color:white;


}

.message-user-btn.btn.btn-secondary.btn-lg:active, .message-user-btn.btn.btn-secondary.btn-lg:focus, .message-user-btn.btn.btn-secondary.btn-lg:focus-within, .message-user-btn.btn.btn-secondary.btn-lg:visited {
    background-color: #FFCD46!important;
    background: #FFCD46!important;
    opacity: 1!important;
    -webkit-filter: brightness(100%)!important;
            filter: brightness(100%)!important;
    color:white;

}

.cancel-stuff{
    width:100%;
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    
    padding-right: 20px;
    
    
}

.container.post-info-container.main-one{

flex-flow: wrap;}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 400 !important;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    /* transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
}

.about-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.about-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}

.about-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.about-modal .modal-header button {
  width: 10%;
}
.about-modal .modal-header h5 {
  font-size: 18px;
}

.about-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.about-modal .loginbutton {
  background-color: #FFCD46;
  border: none;
  border-radius: 0;
  margin: 30px 0 20px 0;
  box-shadow: none;
}

.about-modal .loginbutton:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
}

.about-modal .modal-body {
  padding: 3rem 3rem 0 2rem;
}

.about-modal .modal-body .form-group {
  justify-content: center;
}

.about-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.about-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.about-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.about-modal .social-logins-login button {
  width: 45%;
}

.about-modal input[type="email"],
.about-modal input[type="password"],
.about-modal input[type="text"],
.about-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.about-modal input[type="email"]:focus,
.about-modal input[type="password"]:focus,
.about-modal input[type="name"]:focus,
.about-modal select.form-control:focus {
  box-shadow: none;
}

.login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}

.login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}

.login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}

.eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}

.about-modal input[type="email"],
.about-modal input[type="password"],
.about-modal input[type="name"],
.about-modal select.form-control,
.about-modal .password-input {
  padding-left: 40px;
}

.about-modal .form-control::-webkit-input-placeholder {
  color: #cecece;
}

.about-modal .form-control::-moz-placeholder {
  color: #cecece;
}

.about-modal .form-control:-ms-input-placeholder {
  color: #cecece;
}

.about-modal .form-control::-ms-input-placeholder {
  color: #cecece;
}

.about-modal .form-control::placeholder {
  color: #cecece;
}

body {
  padding-right: 0 !important;
}

​ ​ ​ .about-modal .modal-header {
  color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

​ .about-modal .modal-content {
  border-radius: 0 !important;
  border: none;
}
​ .about-modal .modal-header h5 {
  width: 90%;
  text-align: center;
}

.about-modal .modal-header button {
  width: 10%;
}
.about-modal .modal-header h5 {
  font-size: 18px;
}

.about-modal .modal-header .close {
  color: #FFCD46;
  opacity: 1;
  text-shadow: none;
  font-size: x-large;
}

.about-modal .loginbutton {
  background-color: #FFCD46;
  color: white;
  border: none;
  border-radius: 0;
  margin: 30px 0 20px 0;
  box-shadow: none;
}

.about-modal .loginbutton:hover {
  background-color: rgb(100, 145, 43);
  border: none;
  border-radius: 0;
  border-color: rgb(100, 145, 43);
  box-shadow: none;
}

.about-modal .modal-body {
  padding: 3rem 3rem 0 2rem;
}

.about-modal .modal-body .form-group {
  justify-content: center;
}

.about-modal .forgot-pass-login {
  color: #ADB5BD !important;
  cursor: pointer;
  font-weight: 500;
}

.about-modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.about-modal .social-logins-login {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.about-modal .social-logins-login button {
  width: 45%;
}

.about-modal input[type="email"],
.about-modal input[type="password"],
.about-modal input[type="text"],
.about-modal select.form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  box-shadow: none;
  border-radius: 0;
}

.about-modal input[type="email"]:focus,
.about-modal input[type="password"]:focus,
.about-modal input[type="name"]:focus,
.about-modal select.form-control:focus {
  box-shadow: none;
}
​ .login-with-google.btn-outline-primary {
  border-color: #ADB5BD;
  color: #ADB5BD;
  border-radius: 0;
}
​ .login-with-google.btn-outline-primary:hover {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
}
​ .login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
}
​ .login-with-fb.btn.btn-primary:hover {
  border: 1px solid #ADB5BD;
  background-color: #fff !important;
  color: #ADB5BD;
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}
​ ​ .email-icon-modal,
.pass-icon-modal {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #ADB5BD;
}
​ .eye-icon-modal {
  position: absolute;
  top: 15px;
  right: 25px;
  color: #FFCD46;
}
​ ​ .about-modal input[type="email"],
.about-modal input[type="password"],
.about-modal input[type="name"],
.about-modal select.form-control,
.about-modal .password-input {
  padding-left: 40px;
}
​ .about-modal .form-control::-webkit-input-placeholder {
  color: #cecece;
}
​ .about-modal .form-control::-moz-placeholder {
  color: #cecece;
}
​ .about-modal .form-control:-ms-input-placeholder {
  color: #cecece;
}
​ .about-modal .form-control::-ms-input-placeholder {
  color: #cecece;
}
​ .about-modal .form-control::placeholder {
  color: #cecece;
}
​ body {
  padding-right: 0 !important;
}

.react-rater-star.is-active,
.react-rater-star.will-be-active {
  color: #FFCD46;
}

.carousel-item {
  /* background-color: lightgray; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}
.carousel-item img {
  width: 100%;
  vertical-align: middle;
}
.view-carousel-indicators li {
  width: 11px !important;
  height: 11px !important;
  border-radius: 50% !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border: 2px solid white !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.carousel .slide {
  width: 100% !important;
}
.carousel-indicators {
  width: 100% !important;
}
.carousel-indicators .active {
  border: 2px solid white !important;
  background-color: rgb(255, 255, 255) !important;
}
.carousel-control-next-icon {
  position: relative;
}

.carousel-control-next-icon:before {
  content: "";
  position: absolute;
  top: -9px;
  background-color: rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: -10px;
}
.carousel-control-prev-icon {
  position: relative;
}
.carousel-control-prev-icon:before {
  content: "";
  position: absolute;
  top: -9px;
  background-color: rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: -10px;
}
.reportbutton {
  position: absolute;
  right: 75px;
  z-index: 100;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}
.report-icon,
.goback-icon {
  color: #fff;
}
.report-icon:hover {
  color: red;
}
.backbutton {
  position: absolute;
  left: 75px;
  z-index: 100;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}
video::-webkit-media-controls-enclosure {
  padding: 0px;
  height: 70px;
  position: relative;
}

video::-webkit-media-controls-panel {
  position: absolute;
  left: 15%;
  /* margin: 0 auto; */
  bottom: 50px;
  width: 70%;
  opacity: 1 !important;
  display: -webkit-flex !important;
  margin-top: 50px;
  height: 70px;

  /* background-color: #34495e; */
  /* border-radius: 0 0 10px 10px; */
  background-image: none;
}
.carousel-item > img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.carousel-item,
.carousel-item.active {
  align-items: center;
  height: 100%;
}
.carousel-inner {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  text-align: center;
}
.carousel-inner video {
  align-items: center;
  width: 100%;
  max-height: 600px;
}

@media only screen and (max-width: 600px) {
  .forgot-pass {
    width: 100% !important;
    padding: 20px !important;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
html {
  font-size: 14px;
  font-family: 'Rubik', sans-serif;
}

.green-txt {
  color: #FFCD46;
}

.green-bg {
  background-color: #FFCD46;
}
.App-link {
  color: #61dafb;
}

.btn,
.btn-secondary {
  border-color: transparent;
  border: 0;
}
.btn:hover,
.btn-secondary {
  border-color: transparent;
  border: 0;
}

.fa-star {
  height: 12px;
  margin: 10px 10px 10px 0;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
}

a {
  font-weight: 500;
}

a:focus {
  color: #1F2A37 !important;
  font-weight: 500;
  text-decoration: none;
}

.btn-white:focus {
  color: #1F2A37 !important;
  font-weight: 500;
  text-decoration: none;
}

 a:hover {
  color: #C69500 !important;
  font-weight: 500;
}

* {
  outline: none;
}

.btn:focus {
  outline: none;
}

.show {
  opacity: 1;
}

body,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rubik', sans-serif !important;
}

.btn-primary:focus,
.btn-primary:active,
.btn:focus,
.btn,
.btn:focus-within,
.button:focus,
.button:active {
  box-shadow: none !important;
  outline: 0px !important;
  background-color: transparent;
}

.btn-secondary:focus,
.btn-secondary:active {
  background-color: transparent !important;
}

button {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #ECECEC;
}

.profile-img .img-circle {
  max-height: 100%;
  object-fit: cover;
  border-radius: 0 !important;
}

.dashboard-card {
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  border: 0 !important;
  /* -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09); */
  /* box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09); */
}

.dashboard-card:hover {
  /* -webkit-transform: scale(1.07); */

  /* -ms-transform: scale(1.05); */
  /* transform: scale(1.05); */
  /* -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.21),
    0 5px 15px 0 rgba(0, 0, 0, 0.18); */
  /* box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.21), */
    /* 0 5px 15px 0 rgba(0, 0, 0, 0.18); */
  cursor: pointer;
}

.lowerNav.d-flex.flex-row.justify-content-between.align-items-center {
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}
.card {
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  /* border: solid 2px transparent; */
}

/* .card:hover {
  -webkit-transform: scale(1.03);
  z-index: 100;
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.09);
} */
.card-img {
  /* border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0 !important; */
  border-radius: 30px !important;
}

.page-content.col-sm-12 {
  min-height: 100vh;
}
.Checkout.is-desktop .Modal-animationWrapper .Button {
  position: relative;
  border-radius: 4px;
  background-color: green !important;
  background-image: green !important;
  box-shadow: 0 1px 0 0 rgba(46, 86, 153, 0.15),
    inset 0 1px 0 0 rgba(46, 86, 153, 0.1),
    inset 0 -1px 0 0 rgba(46, 86, 153, 0.4);
  font-size: 17px;
  line-height: 21px;
  height: 37px;
  font-weight: 700;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.page-content {
  /* margin-top: 12.6rem; */
}

.edit-own-post-menu {
  margin-top: -60px;
  margin-bottom: 10px;
}

.buttonrowtwo .btn-secondary,
.buttonrowtwo .btn-secondary:hover,
.buttonrowtwo .btn-secondary:active,
.buttonrowtwo .btn-secondary:visited {
  background-color: #FFCD46 !important;
  background: #FFCD46 !important;
}
.user-cards-ratingss {
  width: 100%;
}

.post-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.rdtTimeToggle {
  background-color: #FFCD46;
}

.loginbutton.btn.btn-secondary.btn-lg.btn-block {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.05) !important;
  background-color: #FFCD46 !important;
}
.loginbutton:active {
  background-color: #FFCD46 !important;
}

.rdtTimeToggle {
  background-color: #FFCD46;
}

.forgot-pass {
  padding: 80px;
  border: 1px solid #efefef;
}

.forgot-pass-input.input-group label {
  display: block;
}

.forgot-pass-input label {
  font-size: 1rem !important;
  margin-bottom: 0 !important;
  padding-top: 10px;
}

.forgot-pass-input .form-control {
  width: 300px;
}

.forgot-pass-submit {
  margin-top: 50px;
}

.forgot-pass-submit button {
  padding: 10px 30px;
}
.login-with-google.btn-outline-primary {
  color: #ADB5BD;
  border-radius: 0;
  border: 1px solid #ADB5BD;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.login-with-fb.btn.btn-primary {
  border-color: #ADB5BD;
  background-color: #ADB5BD;
  color: #fff;
  border-radius: 0;
  border: 1px solid #ADB5BD;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}
::-webkit-scrollbar {
  height: 10px;
  border: 1px solid #FFCD46 !important;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #FFCD46 !important;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:vertical {
  background: #FFCD46 !important;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.history-item-container .post-title {
  font-size: 1.2rem !important;
}
.history-item-container .rightside-info .info2 .info-prof-pic .left-side-pf {
  width: 30px !important;
  height: 30px !important;
}
.card-header {
  background-color: #fff !important;
  border-bottom: 0 !important;
}
.footer-bg {
    background-color: #ADB5BD;
    padding: 20px 0;
    margin-top: 50px;
}

.footer-bg .footer-links {
    background-color: transparent;
    color: #fff;
    font-weight: 400;
    margin: 0 5px;
    border:none;
    text-align: center;
}


 .footer-bg .footer-links:hover, .footer-bg .footer-links:focus, .footer-bg .footer-links:visited, .footer-bg .footer-links:active, .footer-bg .footer-links:focus-within {
background-color: transparent;
border:none;
text-decoration: none;
color:#fff;
} 
