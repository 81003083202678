.carousel-item {
  /* background-color: lightgray; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}
.carousel-item img {
  width: 100%;
  vertical-align: middle;
}
.view-carousel-indicators li {
  width: 11px !important;
  height: 11px !important;
  border-radius: 50% !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border: 2px solid white !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
.carousel .slide {
  width: 100% !important;
}
.carousel-indicators {
  width: 100% !important;
}
.carousel-indicators .active {
  border: 2px solid white !important;
  background-color: rgb(255, 255, 255) !important;
}
.carousel-control-next-icon {
  position: relative;
}

.carousel-control-next-icon:before {
  content: "";
  position: absolute;
  top: -9px;
  background-color: rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: -10px;
}
.carousel-control-prev-icon {
  position: relative;
}
.carousel-control-prev-icon:before {
  content: "";
  position: absolute;
  top: -9px;
  background-color: rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: -10px;
}
.reportbutton {
  position: absolute;
  right: 75px;
  z-index: 100;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}
.report-icon,
.goback-icon {
  color: #fff;
}
.report-icon:hover {
  color: red;
}
.backbutton {
  position: absolute;
  left: 75px;
  z-index: 100;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}
video::-webkit-media-controls-enclosure {
  padding: 0px;
  height: 70px;
  position: relative;
}

video::-webkit-media-controls-panel {
  position: absolute;
  left: 15%;
  /* margin: 0 auto; */
  bottom: 50px;
  width: 70%;
  opacity: 1 !important;
  display: -webkit-flex !important;
  margin-top: 50px;
  height: 70px;

  /* background-color: #34495e; */
  /* border-radius: 0 0 10px 10px; */
  background-image: none;
}
.carousel-item > img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.carousel-item,
.carousel-item.active {
  align-items: center;
  height: 100%;
}
.carousel-inner {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  text-align: center;
}
.carousel-inner video {
  align-items: center;
  width: 100%;
  max-height: 600px;
}
