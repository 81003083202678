 .view-post-tabs .nav-tabs .nav-link.active {
    color: #FFCD46 !important;
    /* background-color: #fff; */
    /* border-bottom-color: #dee2e6 #dee2e6 #fff; */
    border: 0 !important;
    border-bottom: 1px solid #FFCD46 !important;
}

.view-post-tabs .nav-tabs .nav-item {
    font-size: 21px;
    cursor: pointer;
    color: #585858;
}

.view-post-tabs .nav-tabs {
    border-bottom: 0;
}

.button>.inner>button {
    background: #FFCD46 !;
}