.category-card {
    width: 100%;
    margin-bottom: 40px;
    /* margin-right: 10px; */
    background-color: white;
    box-shadow: 0px 3px 12px #00000055;
    padding: 0;
}

.categories-in-dash {
    width: 100%;
    /* padding: 0 15px 0 0; */
    /* margin: 0 30px; */
}
.categories-in-dash.row {
    margin-right: 15px;
    margin-left: 15px;
}

.category-card-title {
    color: black;
    font-size: 27px;
    font-weight: 500;
    padding: 10px 10px;
}
.card-category-img {
    height: 250px;
    overflow: hidden;
}
.card-category-img img {
    vertical-align: middle;
    width: auto;
    min-width: fit-content;
    min-height: 100%;
}
.categories-in-dash.row {
    flex-wrap: nowrap;
}
.categories-in-dash.py-3.row{
    padding-left:20px;}
    .card-category-img{
    height:150px;
}
.category-card-title{
    font-size:18px;
}
@media only screen and (max-width: 1500px) {
    .card-category-img{
        height:100px;
    }
}